import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import ProtectedRoute from "../ProtectedRoute";
import {
  LoginPage,
  HomePage,
  CatalogPage,
  GiftsPage,
  SubscribePage,
  EventsPage,
  BoxesPage,
  OrderPage,
  ArchivePage,
  AddFlowerPage,
  EditFlowerPage,
  ChaptersPage,
  AddChapterPage,
  EditChapterPage,
  CategoriesPage,
  SpecialPage,
  EditCategoriesPage,
  SettingsPage,
} from "../";

class Container extends Component {
  render() {
    return (
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <ProtectedRoute exact path="/" component={HomePage} />
        <ProtectedRoute path="/catalog" component={CatalogPage} />
        <ProtectedRoute path="/addflower" component={AddFlowerPage} />
        <ProtectedRoute path="/editflower" component={EditFlowerPage} />
        <ProtectedRoute path="/gift" component={GiftsPage} />
        <ProtectedRoute path="/subscribe" component={SubscribePage} />
        <ProtectedRoute path="/events" component={EventsPage} />
        <ProtectedRoute path="/special" component={SpecialPage} />
        <ProtectedRoute path="/boxes" component={BoxesPage} />

        <ProtectedRoute path="/order" component={OrderPage} />
        <ProtectedRoute path="/archive" component={ArchivePage} />

        <ProtectedRoute path="/chapters" component={ChaptersPage} />
        <ProtectedRoute path="/addchapter" component={AddChapterPage} />
        <ProtectedRoute path="/editchapter" component={EditChapterPage} />

        <ProtectedRoute path="/categories" component={CategoriesPage} />
        <ProtectedRoute path="/editcategories" component={EditCategoriesPage} />

        <ProtectedRoute path="/settings" component={SettingsPage} />

      </Switch>
    );
  }
}

export default Container;
