import React, { Component } from "react";

import "./UiCardOrderItem.scss";

class UiCardOrderItem extends Component {
  constructor(props) {
    super(props);
  }

  state = {};

  render() {
    return (
      <div className="order-item">
        <img className="order-item-image" src={this.props.image} />
        <div className="order-item-info">
          <h3 className="order-item-title">{this.props.title}</h3>
          <p className="order-item-text">{this.props.text}</p>
        </div>
        <p className="order-item-num">{this.props.num} шт</p>
        <p className="order-item-price">{this.props.price} руб</p>
      </div>
    );
  }
}
export default UiCardOrderItem;
