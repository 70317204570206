import ENV from '../services/Env.js';

export const addFile = (file, _type) => {
  let formData = new FormData();
  formData.append('type', _type);
  formData.append('file', file);

  return fetch(ENV.API_URL + '/files/add', {
    method: 'POST',

    body: formData
  }).then(function (response) {
    return response.json();
  });
}

export const storeData  = (key, value) => {
  let val = JSON.stringify(value);
  return  localStorage.setItem(key, val);
}

export const isJson = (str) => {
try {
    JSON.parse(str);
} catch (e) {
    return false;
}
return true;
}


export const retrieveData   = (key) => {
  return  isJson(localStorage.getItem(key)) ? JSON.parse(localStorage.getItem(key) ) : null;
}

export const addFileBase64 = (_file, _type) => {
 
  return fetch(ENV.API_URL + '/files/add64', {
    method: 'POST',
    body: JSON.stringify({
      file: _file,
      type: _type
    }) 
  }).then(function (response) {
    return response.json();
  });
}

 
export const safeJsonParse = (str, isObj = false) => {
  let val = isObj ? {} : [];
  try {
    JSON.parse(str);
  } catch (e) {
    return val;
  }
  return  JSON.parse(str);
}

