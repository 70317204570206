import ENV from '../services/Env.js';

export const getAll = (_token, _page = 1, _type) => {
 
  return fetch(ENV.API_URL + '/deals/all', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _token,
      page: _page,
      type: _type
    }),
  }).then((response) => {
    return response.json()
  });
}

 