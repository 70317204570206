import React, { Component } from "react";

import "./UiModal.scss";

import UiModal from "./UiModal";

import { ReactComponent as LoaderImage } from "../../assets/images/ui/loader.svg";

class UiLoader extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const modalInner = (
      <div className="loader-overlay">
        <LoaderImage className="loader-gif" />
      </div>
    );
    return (
      <UiModal
        modal="loader"
        modalInner={modalInner}
        modalOpen={this.props.modalOpen}
      />
    );
  }
}

export default UiLoader;
