import React, { Component } from "react";

import "./UiModalCatalog.scss";

import UiButtonOrange from "../buttons/UiButtonOrange";
import UiModal from "./UiModal";
import UiModalCloseButton from "./UiModalCloseButton";
import UiTextInput from "../forms/UiTextInput";
import UiSelect from "../forms/UiSelect";

class UiModalCatalogEdit extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const modalInner = (
      <div className="modal-body">
        <UiModalCloseButton onClick={() => this.props.modalClose()} />
        <h3>Изменить</h3>
        <div className="modal-catalog-form">
          <UiTextInput placeholder="Название" small />
          <UiTextInput placeholder="Описание / Состав" small />
          <UiSelect
            label="Категория"
            optionList={[
              { option: "Обычные букеты" },
              { option: "Букеты невесты" },
              { option: "Мужские букеты" },
            ]}
          />
          <UiSelect
            label="Тип"
            optionList={[
              { option: "нет" },
              { option: "новинка" },
            ]}
          />
          <UiSelect
            label="Размер"
            optionList={[
              { option: "маленький" },
              { option: "обычный" },
              { option: "средний" },
              { option: "большой" },
            ]}
          />
          <UiTextInput placeholder="Стоимость" small />
          <div className="modal-catalog-form-images">
            <p>Изменить изображения</p>
          </div>
          <UiButtonOrange buttonText="Сохранить" small />
        </div>
      </div>
    );
    return (
      <UiModal
        modal="modal-catalog"
        modalInner={modalInner}
        modalOpen={this.props.modalOpen}
      />
    );
  }
}

export default UiModalCatalogEdit;
