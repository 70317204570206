import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ru from 'date-fns/locale/ru';
import "./UiSelect.scss";
import "react-datepicker/dist/react-datepicker.css";

import { ReactComponent as CalendarIcon } from "../../assets/images/ui/calendar.svg";
import { ReactComponent as SelectDownIcon } from "../../assets/images/ui/select-down.svg";

class UiSelectDate extends Component {

  state = {
    selectedDate: new Date()
  }

  constructor(props) {
    super(props);
    registerLocale('ru', ru);
  }

  render() {
    return (
      <div className="select-wrap">
        <label>{this.props.label}</label>
        <div className="select-container">
          <CalendarIcon className="select-calendar" />
          
         

          <DatePicker
            locale="ru"
            dateFormat="dd.MM.yyyy"
            selected={this.state.selectedDate}
            clearIcon={null}
            minDate={new Date()}
            onChange={(e) => {
              this.setState({selectedDate: e})
              this.props.onChange(e)
              console.log(e)
            }}
          />

          <SelectDownIcon className="select-icon" />

        </div>
      </div>
    );
  }
}
export default UiSelectDate;
