import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";

import './UiFooter.scss';

class UiFooter extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-info">
          <p>© 2021 <a href="#">MATE flowers</a>. Все права защищены.</p>
        </div>
      </footer>
    );
  }
}
export default UiFooter;