import React, { Component } from "react";
import { Redirect } from "react-router";

import "./UiButtonRound.scss";

import { Icon } from "@iconify/react";
import deleteIcon from "@iconify-icons/zmdi/delete";
import playlistPlus from "@iconify-icons/zmdi/playlist-plus";
import lockIcon from "@iconify-icons/zmdi/lock";
import lockOpen from '@iconify-icons/zmdi/lock-open';
import floppyIcon from "@iconify-icons/zmdi/floppy";
import mailSend from "@iconify-icons/zmdi/mail-send";
import assignmentReturned from "@iconify-icons/zmdi/assignment-returned";
import eyeIcon from "@iconify-icons/zmdi/eye";

class UiButtonRound extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    redirect: false,
    redirectPage: "",
  };

  handleOnClick = (value) => {
    this.setState({ redirect: true, redirectPage: value });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect push to={"/" + this.state.redirectPage} />;
    }
    return (
      <button
        className={"button-round" + (this.props.checked ? " outline" : "")}
        onClick={
          this.props.navigate
            ? () => this.handleOnClick(this.props.navigate)
            : this.props.onClick
        }
      >
        {this.props.icon == "save" ? (
          <Icon className="button-round-icon" icon={floppyIcon} />
        ) : null}
        {this.props.icon == "lock-open" ? (
          <Icon className="button-round-icon" icon={lockOpen} />
        ) : null}
        {this.props.icon == "lock" ? (
          <Icon className="button-round-icon" icon={lockIcon} color="#e22e5e" />
        ) : null}
        {this.props.icon == "delete" ? (
          <Icon className="button-round-icon" icon={deleteIcon} />
        ) : null}
        {this.props.icon == "listPlus" ? (
          <Icon className="button-round-icon" icon={playlistPlus} />
        ) : null}
        {this.props.icon == "mail" ? (
          <Icon className="button-round-icon" icon={mailSend} />
        ) : null}
        {this.props.icon == "load" ? (
          <Icon className="button-round-icon" icon={assignmentReturned} />
        ) : null}
        {this.props.icon == "open" ? (
          <Icon className="button-round-icon" icon={eyeIcon} />
        ) : null}
      </button>
    );
  }
}
export default UiButtonRound;
