import React, { Component } from "react";

import Select from 'react-select';
import ImageUploading from "react-images-uploading";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import "./Catalog.scss";

import UiButtonOrange from "../../components/buttons/UiButtonOrange";
import UiFooter from "../../components/footer/UiFooter";
import UiHeader from "../../components/header/UiHeader";
import UiLoader from "../../components/modals/UiLoader";
import UiModalImageCrop from "../../components/modals/UiModalImageCrop";
import UiTextInput from "../../components/forms/UiTextInput";
import UiTextArea from "../../components/forms/UiTextArea";
import UiSelect from "../../components/forms/UiSelect";
import UiCheckItem from "../../components/forms/UiCheckItem";

import Env from "../../services/Env";
import { loadUser } from "../../services/Auth";
import { retrieveData } from "../../services/Storage";
import { edit, GetSubCategories, getTags } from "../../services/Flower";
import { getAll, getChaptersByFlower, addFlowerToChapter } from "../../services/Chapters";
import {
  getAll as getAllCategories
}  from "../../services/Categories";
import getCroppedImg from "../../services/CropImage";
import { addFile, addFileBase64 } from "../../services/Storage";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? "lightgreen" : "white",
  borderRadius: '10px',
  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "lightblue" : "lightgrey",
  padding: grid,
  width: 250
});

class EditFlowerPage extends Component {
  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  state = {
    data: null,
    image: null,
    refer: null,
    title: "",
    description: "",
    composition: "",
    category: 0,
    type: 0,
    size: 0,
    price: 0,
    cart_recommendation: 0,
    items: [],
    removeArray: [],
    imagesList: [],
    savedImages: [],
    categoriesList: [
      { option: "Обычные букеты", value: 0 },
      { option: "Букеты невесты", value: 1 },
      { option: "Мужские букеты", value: 2 },
      { option: "Подарки", value: 3 },
      { option: "Мастер класс", value: 4 },
      { option: "Подписка на цветы", value: 5 },
      { option: "Оформление мероприятий", value: 6 },
      { option: "Цветочные боксы", value: 7 },
      { option: "Спец категория", value: 8 },
    ],
    subCategoriesList: [],
    tags: [],

    categoryActionPreSelectList: null,
    categoryActionList: null,
    /* modals */
    loader: false,
  };

  componentDidMount() {
    this._load();
  }


  _convertImgs(_arr) {
    let arr = [];
    _arr.map((item, index) => {
      arr.push({ id: `item-${index}`, content: item });
    })
    return arr;
  }

  _reConvertImgs(_arr) {
    let arr = [];
    _arr.map((item, index) => {
      arr.push(item.content);
    })
    return arr;
  }

  _load() {
    let _user = loadUser();
    let _refer = retrieveData("referPage");
    this.setState({
      user: _user,
      refer: _refer
    });

    let _data = retrieveData("flowerEdit");
    console.log(_data);
    if (_data) {
      this.setState({
        data: _data,
        imagesList: this._convertImgs(_data.imgs)
      }, () => this._loadChapters());
    }

    getTags().then((res) => {
      if(res){
        let arr = [];
        res.response.map((item,index) => {
          arr.push({ option: item.title, value: item.id - 1 })
        }) 
        this.setState({tags: arr})
      }
    })

    getAllCategories().then((res) => {
      if(res){
        let arr = [];
        res.response.map((item,index) => { 
          arr.push({ option: item.title, value: item.id })
        })
        this.setState({categoriesList: arr})
      }
    })
 
    getAll(_user.api_token).then((res) => {
      this.setState({ loader: false });
      if (res.response) {
        let arr = [];
        let grouped = this._groupBy(res.response, "parent_chapter");
        console.log(grouped)

        grouped.map((item) => {
          let tempArr = [];
          let parent = null;
          item.map((currentItem) => {
            if (currentItem.parent_chapter == null) {

              arr.push({
                value: currentItem.id,
                label: currentItem.title
              });

            } else {
              parent = currentItem.parent_chapter;
              tempArr.push({
                value: currentItem.id,
                label: ` --- ${currentItem.title}`
              });
            }
          })
          if (tempArr.length > 0) {
            tempArr.unshift({
              value: parent,
              label: `${this._getChapterName(res.response, parent)}`
            })
          }
          arr = arr.concat(tempArr);
        })


        console.log("merge", arr);
        this.setState({ categoryActionList: arr })
      }


    });

    GetSubCategories().then((res) => {
      console.log(res);
      if (res.response) {
        let arr = [];
        res.response.map((item) => {
          arr.push({ option: item.title, value: item.id });
        });
        this.setState({ subCategoriesList: arr });
      }
    });

  }

  _getChapterName(_arr, _id) {
    let title = null;
    _arr.map((item) => {
      if (item.id == _id) title = item.title;
    })
    return title;
  }

  _groupBy(array, key) {
    let finalArray = [];
    array.forEach(function (element) {
      var newArray = [];
      array.forEach(function (element1) {
        if (element[key] == element1[key]) {
          newArray.push(element1);
        }
      });
      if (!finalArray.some((arrVal) => newArray[0][key] == arrVal[0][key])) {
        finalArray.push(newArray);
      }
    });
    //console.log(array, finalArray)
    return finalArray;
  }

  _loadChapters() {
    getChaptersByFlower(this.state.data.id).then((res) => {

      let arr = [];
      res.response.map((item) => {

        if (item.item) {
          arr.push({
            value: item.category_id,
            label: item.item.title
          });
        }

      })
      console.log("pre sel", arr);
      this.setState({ categoryActionPreSelectList: arr })
    })
  }

  _edit(id, val) {
    var _data = this.state.data;
    _data[id] = val;
    this.setState({ data: _data });
  }

  _remImg(index, id) {
    let arr = this.state.data.imgs;
    let _removeArray = this.state.removeArray;
    _removeArray.push(id);
    arr.splice(index, 1);
    this.setState({ 
      removeArray: _removeArray,
      imagesList: this._convertImgs(arr) 

    });
    this._edit("imgs", arr);
  }

  _onSaveImage(_croppedAreaPixels) {
    this.setState({ isDialogCrop: false });
    getCroppedImg(this.state.savedImages[0], _croppedAreaPixels, 0).then((res) => {
      this.setState(
        {
          selectedFileName: res,
        },
        () => {
          let arr = this.state.savedImages;
          arr.splice(0, 1);
          this.setState({ savedImages: arr }); 
          this.upload();
        }
      );
    });
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  upload(e) {
    this.setState({ imageLoad: true });
    addFileBase64(this.state.selectedFileName, 0).then((res) => {
      this.setState({ imageLoad: false });
      if (res.success) {
        let arr = this.state.data.imgs;
        arr.push(res.response);
        this.setState({
          imagesList: this._convertImgs(arr) 
        })
        this._edit("imgs", arr);

        if (this.state.savedImages.length > 0) this.setState({ isDialogCrop: true });
      } else {
        console.log(res);
      }
    });
  }

  checkGiftCategory(_id) {
    let ff = false;
    this.state.categoryActionPreSelectList.map((item) => {
      if (item.value == _id) ff = true;
    })



    if (!ff) {
      addFlowerToChapter(this.state.user.api_token, this.state.data.id, _id).then((res) => {
        console.log(res);
        this._loadChapters();
      })
    }

  }

  checkDeleteItems(arr) {

    this.state.categoryActionPreSelectList.map((item) => {
      let f = false;
      arr.map((item2) => {
        if (item.value == item2.value) f = true;
      })
      if (!f) {
        addFlowerToChapter(this.state.user.api_token, this.state.data.id, item.value).then((res) => {
          console.log(res);
          this._loadChapters();
        })
      }
    })



  }


  toggleGiftCategory(_id) {

    addFlowerToChapter(this.state.user.api_token, this.state.data.id, _id).then((res) => {
      console.log(res);
      this._loadChapters();
    })

  }


  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      this.state.imagesList,
      result.source.index,
      result.destination.index
    );

    console.log(items);
    this.setState({
      imagesList: items
    });
  }

  editFlower() {
    console.log(
      this.state.user.api_token,
      this.state.title,
      this.state.description,
      this.state.composition,
      this.state.category,
      this.state.type,
      this.state.size,
      this.state.price,
      this.state.data.imgs,
      this.state.data.xml_id
    );
    if (
      this.state.data.imgs.length > 0 &&
      this.state.data.title != "" &&
      this.state.data.description != ""
    ) {
      let _img = this._reConvertImgs(this.state.imagesList);
      edit(
        this.state.user.api_token,
        this.state.data.id,
        this.state.data.title,
        this.state.data.description,
        this.state.data.composition,
        this.state.data.category,
        this.state.data.sub_category,
        this.state.data.type,
        this.state.data.size,
        this.state.data.price,
        this.state.data.cart_recommendation,
        _img,
        this.state.data.sort,
        this.state.removeArray,
        this.state.data.xml_id
      ).then((res) => {
        console.log(res);
        if (res.response) {
          let refer = "catalog";
          if (retrieveData("referPage")) refer = retrieveData("referPage");
          this.props.history.push("/" + refer);
        }
      });
    } else {
    }
  }

  render() {
    if (this.state.data) {
      var images = this.state.data.imgs.map((item, index) => {
        return (
          <label className="inter-add-image" key={index}>
            <div className="edit-image-wrap">
              <img key={index} src={Env.PUBLIC_URL + item} className="image" />
              <button
                className="edit-image-button"
                onClick={() => this._remImg(index, item)}
              >
                Удалить
              </button>
            </div>
          </label>
        );
      });

      return (
        <div ref={this.divPage} id="page" className="edit-flower-page">
          <UiHeader page="catalog" />
          <div className="content">
            <div className="section section-column">
              <h2>Редактировать товар:</h2>
              <div className="users-table-wrap edit-flower-table-wrap">

                <div className="add-content">
                  <div className="modal-catalog-form">
                    <UiTextInput
                      placeholder="Название"
                      small
                      value={this.state.data.title}
                      onChange={(val) => this._edit("title", val)}
                    />
                    <UiTextArea
                      placeholder="Описание  "
                      small
                      value={this.state.data.description}
                      onChange={(val) => this._edit("description", val)}
                    />
                    <UiTextArea
                      placeholder="Состав"
                      small
                      value={this.state.data.composition}
                      onChange={(val) => this._edit("composition", val)}
                    />
                    <UiSelect
                      label={this.state.refer != "gift" ? "Категория" : "Раздел"}
                      onChange={(val) => this._edit("category", val)}
                      selected={this.state.data.category}
                      optionList={this.state.categoriesList}
                    />
                    {this.state.refer != "gift" ?

                      this.state.subCategoriesList.length > 0 ? (
                        <UiSelect
                          label="Под Категория"
                          onChange={(val) => this._edit("sub_category", val)}
                          selected={this.state.data.sub_category}
                          optionList={this.state.subCategoriesList}
                        />
                      ) : null

                      : null}
                    <UiSelect
                      label="Тип"
                      onChange={(val) => this._edit("type", val)}
                      selected={this.state.data.type}
                      optionList={this.state.tags}
                    />
                    <UiSelect
                      label="Размер"
                      selected={this.state.data.size}
                      onChange={(val) => this._edit("size", val)}
                      optionList={[
                        { option: "маленький 10см", value: 1 },
                        { option: "средний 25см", value: 2 },
                        { option: "большой 40см", value: 3 },
                        { option: "большой 55+см", value: 4 },
                      ]}
                    />
                    <UiTextInput
                      placeholder="Стоимость"
                      value={this.state.data.price}
                      small
                      onChange={(val) => this._edit("price", val)}
                    />

                    <UiTextInput
                      placeholder="XML ID"
                      value={this.state.data.xml_id}
                      small
                      onChange={(val) => this._edit("xml_id", val)}
                    />

                    <UiCheckItem
                      label="Подарки в корзине"
                      defaultValue={this.state.data.cart_recommendation == 1 ? true : false}
                      onChange={(val) => {
                        if (val === true)
                          this._edit("cart_recommendation", 1);
                        else this._edit("cart_recommendation", 0);
                      }}
                    />

                    {this.state.categoryActionPreSelectList && this.state.categoryActionList ?
                      <Select
                        className={"multi-category-selector"}
                        options={this.state.categoryActionList}
                        isMulti={true}
                        placeholder={"Категория подарков"}
                        defaultValue={this.state.categoryActionPreSelectList}
                        onChange={(val) => {
                          console.log(val);
                          if (val.length == 0) {
                            this.state.categoryActionPreSelectList.map((item) => this.toggleGiftCategory(item.value))
                          } else {
                            if (val.length > this.state.categoryActionPreSelectList.length) {
                              val.map((item) => this.checkGiftCategory(item.value));
                            } else {
                              this.checkDeleteItems(val);
                            }
                          }

                        }}
                      />
                      : null}


                    <label className="edit-image-button">
                      <b>Добавить изображения</b>
                      <input
                        type="file"
                        multiple="multiple"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          console.log(e)
                          if (e.target.files.length > 0) {
                            for (let i = 0; i < e.target.files.length; i++) {
                              let arr = this.state.savedImages;
                              this.getBase64(e.target.files[i], (result) => {
                                arr.push(result);
                                this.setState({
                                  savedImages: arr,
                                  base64Image: result,
                                  isDialogCrop: true,
                                });
                              });
                            }
                          }
                        }}
                      />
                    </label>

                    <UiButtonOrange
                      buttonText="Сохранить"
                      small
                      onClick={() => this.editFlower()}
                    />
                  </div>
                </div>

                <div className="modal-catalog-form-images">

                  <DragDropContext onDragEnd={this.onDragEnd}>

                    <Droppable droppableId="droppable">
                      {(provided, snapshot) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={getListStyle(snapshot.isDraggingOver)}
                        >
                          {this.state.imagesList.map((item, index) => (
                            <Draggable key={item.id} draggableId={item.id} index={index}>
                              {(provided, snapshot) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >

                                  <label className="inter-add-image" key={index}>
                                    <div className="edit-image-wrap">
                                      <img key={index} src={Env.PUBLIC_URL + item.content} className="image" />
                                      <button
                                        className="edit-image-button"
                                        onClick={() => this._remImg(index, item.content)}
                                      >
                                        Удалить
                                      </button>
                                    </div>
                                  </label>

                               
                                </div>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                </div>
              </div>
            </div>
            <UiFooter />
          </div>

          {/* modals */}
          <UiLoader modalOpen={this.state.loader} />

          <UiModalImageCrop
            modalOpen={this.state.isDialogCrop}
            base64Image={this.state.savedImages[0]}
            onSave={(val) => this._onSaveImage(val)}
            cancelClick={() => this.setState({ isDialogCrop: false })}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default EditFlowerPage;
