import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

import { logout } from "../../services/Auth";
import "./UiHeader.scss";

import UiButtonMenu from "../buttons/UiButtonMenu";

class UiHeader extends Component {
  state = {
    activeMobile: false,
    profileLogin: false,
    topOffset: 0,
  };

  constructor(props) {
    super(props);

    window.addEventListener("scroll", (event) => {
      let scroll = document.documentElement.scrollTop;
      this.setState({ topOffset: scroll });
    });
  }

  _toggleMobileMenu = () => {
    if (this.state.activeMobile) {
      this._closeMobileMenu();
    } else {
      let top = this.state.topOffset;
      this.setState({ activeMobile: true, topScrollConst: top });
      let element = document.getElementById("page");
      ReactDOM.findDOMNode(element).style.top = -top + "px";
      ReactDOM.findDOMNode(element).style.position = "fixed";
    }
  };

  _closeMobileMenu = () => {
    let element = document.getElementById("page");
    ReactDOM.findDOMNode(element).style.position = "static";
    this.setState({ activeMobile: false }, () => {
      window.scrollTo({
        top: this.state.topScrollConst,
      });
    });
  };

  _scrollToTargetAdjusted(target) {
    this.setState({ activeMobile: false });
    var element = document.getElementById(target);
    var headerOffset = -82;
    if (window.innerWidth < 991) headerOffset = -82;
    var elementPosition = element.offsetTop;
    var offsetPosition = elementPosition - headerOffset;

    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth",
    });
  }

  componentDidMount() {
    this.setState({ profileLogin: this.props.profile });
  }

  logout() {
    logout();
    window.location = "/login";
  }

  render() {
    return (
      <header
        className={"header" + (this.state.activeMobile ? " active-mobile" : "")}
      >
        <div className="header-control">
          <UiButtonMenu onClick={() => this._toggleMobileMenu()} />
          <h2>
            MATE <br />
            Flowers
          </h2>
        </div>
        <nav className="nav">
          <ul className="nav-list">
            <li className="nav-list-item">
              <Link
                className={
                  "nav-list-item-link" +
                  (this.props.page == "home" ? " active" : "")
                }
                to="/"
              >
                Активные заказы
              </Link>
            </li>
            <li className="nav-list-item">
              <Link
                className={
                  "nav-list-item-link" +
                  (this.props.page == "archive" ? " active" : "")
                }
                to="/archive"
              >
                Архив заказов
              </Link>
            </li>
            <li className="nav-list-item">
              <Link
                className={
                  "nav-list-item-link" +
                  (this.props.page == "catalog" ? " active" : "")
                }
                to="/catalog"
              >
                Каталог букетов
              </Link>
            </li>

            <li className="nav-list-item">
              <Link
                className={
                  "nav-list-item-link" +
                  (this.props.page == "subscribe" ? " active" : "")
                }
                to="/subscribe"
              >
                Подписка на цветы
              </Link>
            </li>

            <li className="nav-list-item">
              <Link
                className={
                  "nav-list-item-link" +
                  (this.props.page == "events" ? " active" : "")
                }
                to="/events"
              >
                Оформление мероприятий
              </Link>
            </li>

            <li className="nav-list-item">
              <Link
                className={
                  "nav-list-item-link" +
                  (this.props.page == "boxes" ? " active" : "")
                }
                to="/boxes"
              >
                Цветочные боксы
              </Link>
            </li>

            <li className="nav-list-item">
              <Link
                className={
                  "nav-list-item-link" +
                  (this.props.page == "gift" ? " active" : "")
                }
                to="/gift"
              >
                Каталог подарков
              </Link>
            </li>

            <li className="nav-list-item">
              <Link
                className={
                  "nav-list-item-link" +
                  (this.props.page == "chapters" ? " active" : "")
                }
                to="/chapters"
              >
                Разделы
              </Link>
            </li>

            <li className="nav-list-item">
              <Link
                className={
                  "nav-list-item-link" +
                  (this.props.page == "categories" ? " active" : "")
                }
                to="/categories"
              >
                Страницы
              </Link>
            </li>

            <li className="nav-list-item">
              <Link
                className={
                  "nav-list-item-link" +
                  (this.props.page == "special" ? " active" : "")
                }
                to="/special"
              >
                Спец. страница
              </Link>
            </li>

            <li className="nav-list-item">
              <Link
                className={
                  "nav-list-item-link" +
                  (this.props.page == "settings" ? " active" : "")
                }
                to="/settings"
              >
                Настройки магазина
              </Link>
            </li>

            <li className="nav-list-item">
              <span className="nav-list-item-link" onClick={() => logout()}>
                Выход
              </span>
            </li>
          </ul>
        </nav>
      </header>
    );
  }
}
export default UiHeader;
