import ENV from '../services/Env.js';

export const checkLogin = (log, pass) => {
 
  return fetch(ENV.API_URL + '/manager/login', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      password: pass,
      login: log,
    }),
  }).then((response) => {
    return response.json()
  });
}

 