import React, { Component } from 'react';
import { Redirect } from 'react-router';

import './UiButtonOrange.scss';

class UiButtonOrange extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    redirect: false,
    redirectPage: "",
  }

  handleOnClick = (value) => {
    this.setState({ redirect: true, redirectPage: value })
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={"/" + this.state.redirectPage} />
    }
    return (
      <button className={"button button-orange" + (this.props.small ? " small" : "") + (this.props.green ? " green" : "")} disabled={this.props.disabled} onClick={this.props.navigate ? () => this.handleOnClick(this.props.navigate) : this.props.onClick}>
        <span>{this.props.buttonText}</span>
      </button>
    );
  }
}
export default UiButtonOrange;