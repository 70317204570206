import ENV from '../services/Env.js';

export const getAll = () => {

    return fetch(ENV.API_URL + '/settings/all', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        }, 
    }).then((response) => {
        return response.json()
    });
}


export const update = ( _date) => {

    return fetch(ENV.API_URL + '/settings/update', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(_date),
    }).then((response) => {
        return response.json()
    });
}


export const getInterval = (_token, _date) => {

    return fetch(ENV.API_URL + '/interval/get/available/all', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            api_token: _token,
            date: _date
        }),
    }).then((response) => {
        return response.json()
    });
}


export const getTypeInterval = (_token, _date, _type, _shop, _delivery) => {

    return fetch(ENV.API_URL + '/interval/get/available/type', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            api_token: _token,
            date: _date,
            type: _type,
            shop: _shop,
            delivery: _delivery
        }),
    }).then((response) => {
        return response.json()
    });
}

export const toggleInterval = (_token, _date, _arr, _flag) => {

    return fetch(ENV.API_URL + '/interval/toggle', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            api_token: _token,
            date: _date,
            intervalId: _arr,
            flag: _flag
        }),
    }).then((response) => {
        return response.json()
    });
}


export const getMinimalPriceToDay = (_token, _date ) => {

    return fetch(ENV.API_URL + '/price/get/minimal/date', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            api_token: _token,
            date: _date,
   
        }),
    }).then((response) => {
        return response.json()
    });
}

export const getHistoryMinimalPriceToDay = (_token, _date ) => {

    return fetch(ENV.API_URL + '/price/get/history/date', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            api_token: _token,
            date: _date,
   
        }),
    }).then((response) => {
        return response.json()
    });
}




export const addMinimalPriceToDay = (_token, _date, _price = 0, _type ) => {

    return fetch(ENV.API_URL + '/price/add/minimal/date', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            api_token: _token,
            date: _date,
            price: _price,
            type: _type,
   
        }),
    }).then((response) => {
        return response.json()
    });
}

export const editMinimalPriceToDay = (_token, _id, _price ) => {

    return fetch(ENV.API_URL + '/price/edit/minimal/date', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            api_token: _token,
            id: _id,
            price: _price, 
        }),
    }).then((response) => {
        return response.json()
    });
}


export const getDeliveryPrices = () => {
    return fetch(ENV.API_URL + '/price/get/delivery', {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        } 
    }).then((response) => {
        return response.json()
    });
}

export const editDeliveryPrice = ( _data ) => {
    return fetch(ENV.API_URL + '/price/edit/delivery', {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(_data),
    }).then((response) => {
        return response.json()
    });
}

