import React, { Component } from "react";

import "./Catalog.scss";

import UiAlert from "../../components/modals/UiAlert";
import UiButtonOrange from "../../components/buttons/UiButtonOrange";
import UiButtonRound from "../../components/buttons/UiButtonRound";
import UiButtonSort from "../../components/buttons/UiButtonSort";
import UiFooter from "../../components/footer/UiFooter";
import UiHeader from "../../components/header/UiHeader";
import UiLoader from "../../components/modals/UiLoader";
import UiModalCatalogEdit from "../../components/modals/UiModalCatalogEdit";
import UiTextInput from "../../components/forms/UiTextInput";
import UiSearchInput from "../../components/forms/UiSearchInput";
import UiSelect from "../../components/forms/UiSelect";
import UiPaginator from "../../components/bars/UiPaginator";

import Env from "../../services/Env";
import {
  getAll,
  removeFlower,
  blockFlower,
  edit,
  getTags,
} from "../../services/Flower";
import { getAll as getAllCategories } from "../../services/Categories";
import { storeData } from "../../services/Storage";
import { loadUser } from "../../services/Auth";

class CatalogPage extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    searchLine: "",
    catalogList: [],
    categoriesList: [
      "Обычные букеты",
      "Букеты невесты",
      "Мужские букеты",
      "Подарки",
      "Мастер класс",
      "Подписка на цветы",
      "Оформление мероприятий",
      "Цветочные боксы",
      "Спец категория",
    ],
    tags: [],
    categoriesSortList: [],
    catalogFullList: [],
    sortKey: false,
    currentCategory: null,
    /* modals */
    loader: false,
    isAlertBlock: false,
    isAlert: false,
    modalCatalogActive: false,
    modalCatalogEditActive: false,
    /* paginator */
    currentPage: 1,
    lastPage: 1,
    firstPage: 1,
  };

  componentDidMount() {
    this._load();
  }

  _search(val) {
    let arr =
    val == ""
        ? this.state.catalogFullList
        : this.state.catalogFullList.filter((item) => {
          if (
 
              this._checkInclude(item.id, val) ||
                this._checkInclude(item.title, val) ||
                this._checkInclude(item.description, val) ||
                this._checkInclude(item.composition, val)
          ) { 
           

            return item;
          }
        
        });
       
    this.setState({
      catalogList: arr,
      searchLine: val, 
      currentPage: 0,
      lastPage: arr.length - 1,
      firstPage: 0,
    });

    this.setState({  });
  }

  _checkInclude(substring, _line) {
    if (substring) {
      if (_line) {
        let str = _line;
        return (
          substring.toString().toLowerCase().indexOf(str.toLowerCase()) !== -1
        );
      }
    }
  }

  _sort(_title) {
    let arr = this.state.catalogList;
    arr.sort((a, b) => {
      var keyA = a[_title],
        keyB = b[_title];
      if (this.state.sortKey) {
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
      } else {
        if (keyA < keyB) return 1;
        if (keyA > keyB) return -1;
      }
      return 0;
    });
    this.setState({ sortKey: !this.state.sortKey, catalogList: arr });
  }

  _edit(_item, _val) {
    edit(
      this.state.user.api_token,
      _item.id,
      _item.title,
      _item.description,
      _item.composition,
      _item.category,
      _item.sub_category,
      _item.type,
      _item.size,
      _item.price,
      _item.cart_recommendation,
      _item.imgs,
      _val,
      [],
      _item.xml_id
    ).then((res) => {
      this._load();
    });
  }

  _load() {
    let _user = loadUser();
    this.setState({
      user: _user,
      loader: true,
    });

    getAll(_user.api_token, this.state.currentPage).then((res) => {
      this.setState({ loader: false });
      let arr = [];
      if (res.response) {
        res.response.map((item) => {
          // if (item.category == 0 || item.category == 1 || item.category == 2) {
          if (item.imgs) item.imgs = item.imgs.split(",");
          else item.imgs = [];
          arr.push(item);
          // }
        });
        this.setState({
          currentPage: 0,
          lastPage: arr.length - 1,
          firstPage: 0,
          catalogList: arr.sort((a, b) => a.category - b.category),
          catalogFullList: arr.sort((a, b) => a.category - b.category),
        });
      } else {
        this.props.history.push("/login");
      }
    });

    getAllCategories().then((res) => {
      if (res) {
        let arr = [];
        let arr2 = [{ option: "Все", value: null }];
        res.response.map((item, index) => {
          arr.push(item.title);
          arr2.push({ option: item.title, value: item.id });
        });
        this.setState({ categoriesList: arr, categoriesSortList: arr2 });
      }
    });

    getTags().then((res) => {
      if (res) {
        let arr = [];
        res.response.map((item, index) => {
          arr[item.id - 1] = item.title;
        });
        this.setState({ tags: arr });
      }
    });
  }

  remove() {
    removeFlower(this.state.user.api_token, this.state.selectedId).then(
      (res) => {
        console.log(res);
        this.setState({ isAlert: false });
        this._load();
      }
    );
  }

  block() {
    blockFlower(this.state.user.api_token, this.state.selectedId).then(
      (res) => {
        console.log(res);
        this.setState({ isAlertBlock: false });
        this._load();
      }
    );
  }

  render() {
    let catalog = this.state.catalogList.map((item, index) => {
   
        
        
          if (
            this.state.currentPage * 20 <= index &&
            (this.state.currentPage * 20 + 20) >= index
          ) {
          return (
            <tr key={index}>
              <td>{item.title}</td>
              <td>
                {" "}
                <span className="small">{item.description} </span>{" "}
              </td>
              <td>{this.state.categoriesList[item.category]}</td>
              <td>{this.state.tags[item.type]}</td>
              <td>
                <UiTextInput
                  small
                  value={item.sort}
                  onChange={(val) => this._edit(item, val)}
                />
              </td>
              <td>{item.size}</td>
              <td>{item.price}</td>
              <td>
                {item.imgs.length > 0 ? (
                  <img src={Env.PUBLIC_URL + item.imgs[0]} className="image" />
                ) : (
                  "Нет изображения"
                )}
              </td>
              <td>
                <div>
                  <UiButtonRound
                    icon="open"
                    onClick={() => {
                      storeData("flowerEdit", item);
                      storeData("referPage", "catalog");
                      this.props.history.push("/editflower");
                    }}
                  />
                  <UiButtonRound
                    icon={item.is_block ? "lock" : "lock-open"}
                    onClick={() =>
                      this.setState({
                        selectedId: item.id,
                        isAlertBlock: true,
                      })
                    }
                  />

                  <UiButtonRound
                    icon="delete"
                    onClick={() =>
                      this.setState({ isAlert: true, selectedId: item.id })
                    }
                  />
                </div>
              </td>
            </tr>
          );
        } 
    });

    return (
      <div ref={this.divPage} id="page">
        <UiHeader page="catalog" />
        <div className="content">
          <div className="section">
            <div className="users-table-wrap">
              <h2>Список товаров:</h2>
              <UiSearchInput
                placeholder="Найти"
                callBack={(line) => this._search(line)}
              />

              <div className="add-button-wrap">
                <UiButtonOrange
                  buttonText="Добавить букет"
                  onClick={() => {
                    storeData("referPage", "catalog");
                    this.props.history.push("/addflower");
                  }}
                  small
                />
              </div>

              <div className="add-button-wrap">
                <UiSelect
                  label="Категория"
                  onChange={(val) => {
                    let arr =
                    val == null
                        ? this.state.catalogFullList
                        : this.state.catalogFullList.filter(
                            (a) => val == a.category
                          );
                    this.setState({
                      currentCategory: val,
                      catalogList: arr,
                      currentPage: 0,
                      lastPage: arr.length - 1,
                      firstPage: 0,
                    });
                  }}
                  optionList={this.state.categoriesSortList}
                />
              </div>

              {/* Таблица товаров */}
              <table className="table users-table">
                <tbody>
                  <tr>
                    <th>
                      <UiButtonSort sortStart={true} title="Название" />
                    </th>
                    <th>Описание / Состав</th>
                    <th>
                      <UiButtonSort
                        sortStart={true}
                        title="Категория"
                        onClick={() => this._sort("category")}
                      />
                    </th>
                    <th>
                      <UiButtonSort
                        sortStart={false}
                        title="Тип"
                        onClick={() => this._sort("type")}
                      />
                    </th>
                    <th>
                      <UiButtonSort
                        sortStart={true}
                        title="Сортировка"
                        onClick={() => this._sort("sort")}
                      />
                    </th>
                    <th>
                      <UiButtonSort
                        sortStart={false}
                        title="Размер"
                        onClick={() => this._sort("size")}
                      />
                    </th>
                    <th>
                      <UiButtonSort
                        sortStart={false}
                        title="Стоимость"
                        onClick={() => this._sort("price")}
                      />
                    </th>
                    <th>Изображения</th>
                    <th></th>
                  </tr>

                  {catalog.length > 0 ? (
                    catalog
                  ) : (
                    <tr>
                      <td colSpan={8}>Нет добавленных товаров</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* paginator */}
          <UiPaginator
            onChange={(val) => {
              this.setState(
                {
                  currentPage: val,
                } );
            }}
            lastPage={this.state.lastPage}
            currentPage={this.state.currentPage}
          />

          <UiFooter />
        </div>

        {/* modals */}
        <UiLoader modalOpen={this.state.loader} />

        <UiAlert
          modalOpen={this.state.isAlert}
          title="Внимание!"
          text="Вы не сможете отменить удаление. Продолжить?"
          onClick={() => this.remove()}
          cancelClick={() => this.setState({ isAlert: false })}
        />

        <UiAlert
          modalOpen={this.state.isAlertBlock}
          title="Заблокировать?"
          text="Товар не будет отображаться в каталоге. Продолжить?"
          onClick={() => {
            this.block();
          }}
          cancelClick={() => this.setState({ isAlertBlock: false })}
        />

        <UiModalCatalogEdit
          modalOpen={this.state.modalCatalogEditActive}
          modalClose={() => this.setState({ modalCatalogEditActive: false })}
        />
      </div>
    );
  }
}

export default CatalogPage;
