import React, { Component } from 'react';
import { Link } from "react-router-dom";

import './UiButtonSort.scss';

import { Icon } from '@iconify/react';
import chevronDown from '@iconify-icons/zmdi/chevron-down';
import chevronUp from '@iconify-icons/zmdi/chevron-up';

class UiButtonSort extends Component {
  render() {
    return (
      <button className="button-sort" onClick={this.props.onClick}>
        {this.props.title}
        {/*<Icon className="button-sort-icon" icon={unfoldMore} />*/}
        {/*this.props.sortStart ?
          <Icon className="button-sort-icon" icon={chevronDown} />
          :
          <Icon className="button-sort-icon" icon={chevronUp} />
        */}
      </button>
    );
  }
}
export default UiButtonSort;