import ENV from '../services/Env.js';

export const getAll = (_token) => {
 
  return fetch(ENV.API_URL + '/categories/all', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
 
  }).then((response) => {
    return response.json()
  });
}

export const getCategoryById= (_id) => {

  return fetch(`${ENV.API_URL}/categories/id/${_id}`, {
      method: 'GET',
  }).then(function (response) {
      return response.json();
  });
}

export const edit = (_api, _id, _title ) => {
 
  return fetch(ENV.API_URL+'/categories/edit', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_token: _api,
        id: _id,  
        title: _title,  
        
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const add = (_api,  _title) => {
 debugger;
  return fetch(ENV.API_URL+'/categories/add', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_token: _api,
        title: _title,  
         
      }) 
    }).then(function(response) {
      return response.json();
    });
}
 

export const block = (_api, _id ) => {
 
  return fetch(ENV.API_URL+'/categories/block', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_token: _api,
        id: _id,
 
      }) 
    }).then(function(response) {
      return response.json();
    });
}
