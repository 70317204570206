import React, { Component } from "react";

import "./Catalog.scss";

import UiButtonOrange from "../../components/buttons/UiButtonOrange";
import UiFooter from "../../components/footer/UiFooter";
import UiHeader from "../../components/header/UiHeader";
import UiLoader from "../../components/modals/UiLoader";
import UiModalImageCrop from "../../components/modals/UiModalImageCrop";
import UiTextInput from "../../components/forms/UiTextInput";
import UiTextArea from "../../components/forms/UiTextArea";
import UiSelect from "../../components/forms/UiSelect";
import UiCheckItem from "../../components/forms/UiCheckItem";

import Env from "../../services/Env";
import { loadUser } from "../../services/Auth";
import { add } from "../../services/Flower";
import getCroppedImg from "../../services/CropImage";
import { addFile, addFileBase64, retrieveData } from "../../services/Storage";
import {getAll as getAllCategories}  from "../../services/Categories";

class AddFlowerPage extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    image: null,
    title: "",
    description: "",
    composition: "",
    category: 0,
    type: 0,
    size: 0,
    price: 0,
    cart_recommendation: 0,
    categoriesList: [
      { option: "Обычные букеты", value: 0 },
      { option: "Букеты невесты", value: 1 },
      { option: "Мужские букеты", value: 2 },
      { option: "Подарки", value: 3 },
      { option: "Мастер класс", value: 4 },
      { option: "Подписка на цветы", value: 5 },
      { option: "Оформление мероприятий", value: 6 },
      { option: "Цветочные боксы", value: 7 },
      { option: "Спец категория", value: 8 },
    ],

    savedImages: [],
    imagesList: [],
    /* modals */
    loader: false,
    xml_id: null
  };

  componentDidMount() {
    this._load();
  }

  _load() {
    let _user = loadUser();
    let _refer = retrieveData("referPage");

    this.setState({
      user: _user,
      refer: _refer
    });

    getAllCategories().then((res) => {
      if(res){
        let arr = [];
        res.response.map((item,index) => {
          arr.push({ option: item.title, value: item.id })
        })
        this.setState({categoriesList: arr})
      }
    })
  }

  _onSaveImage(_croppedAreaPixels) {
    this.setState({ isDialogCrop: false });
    getCroppedImg(this.state.savedImages[0], _croppedAreaPixels, 0).then((res) => {
      this.setState(
        {
          selectedFileName: res,
        },
        () => {
          let arr = this.state.savedImages;
          arr.splice(0,1);
          this.setState({ savedImages: arr});
          if(arr.length > 0) this.setState({ isDialogCrop: true });
          this.upload();
        }
      );
    });
  }

  getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  upload(e) {
    this.setState({ imageLoad: true });
    addFileBase64(this.state.selectedFileName, 0).then((res) => {
      this.setState({ imageLoad: false });
      if (res.success) {
        let arr = this.state.imagesList;
        arr.push(res.response);
        this.setState({ imagesList: arr });
      } else {
        console.log(res);
      }
    });
  }

  addFlower() {
    console.log(
      this.state.user.api_token,
      this.state.title,
      this.state.description,
      this.state.composition,
      this.state.category,
      this.state.type,
      this.state.size,
      this.state.price,
      this.state.imagesList,
      this.state.xml_id
    );
    if (
      this.state.imagesList.length > 0 &&
      this.state.title != "" &&
      this.state.description != ""
    ) {
      add(
        this.state.user.api_token,
        this.state.title,
        this.state.description,
        this.state.composition,
        this.state.category,
        this.state.type,
        this.state.size,
        this.state.price,
        this.state.cart_recommendation,
        this.state.imagesList,
        this.state.xml_id
      ).then((res) => {
        console.log(res);
        if (res.response) {
          let refer = "catalog";
          if (retrieveData("referPage")) refer = retrieveData("referPage");
          this.props.history.push("/" + refer);
        }
      });
    } else {
    }
  }

  render() {
    var images = this.state.imagesList.map((item, index) => {
      return <img key={index} src={Env.PUBLIC_URL + item} className="image" />;
    });

    return (
      <div ref={this.divPage} id="page" className="edit-flower-page">
        <UiHeader page="catalog" />
        <div className="content">
          <div className="section">
            <div className="users-table-wrap">
              <h2>Добавить товар:</h2>
              <div className="add-content">
                <div className="modal-catalog-form">
                  <UiTextInput
                    placeholder="Название"
                    small
                    onChange={(val) => this.setState({ title: val })}
                  />
                  <UiTextArea
                    placeholder="Описание  "
                    small
                    onChange={(val) => this.setState({ description: val })}
                  />
                  <UiTextArea
                    placeholder="Состав"
                    small
                    onChange={(val) => this.setState({ composition: val })}
                  />
                  <UiSelect
                    label="Категория"
                    onChange={(val) => this.setState({ category: val })}
                    optionList={this.state.categoriesList}
                  />
                  <UiSelect
                    label="Тип"
                    onChange={(val) => this.setState({ type: val })}
                    optionList={[
                      { option: "Нет", value: 0 },
                      { option: "Новинка", value: 1 },
                      { option: "Лимитированная коллекция", value: 2 },
                      { option: "Лидер продаж", value: 3 },
                    ]}
                  />
                  <UiSelect
                    label="Размер"
                    onChange={(val) => this.setState({ size: val })}
                    optionList={[
                      { option: "маленький 10см", value: 1 },
                      { option: "средний 25см", value: 2 },
                      { option: "большой 40см", value: 3 },
                      { option: "большой 55+см", value: 4 },
                    ]}
                  />
                  <UiTextInput
                    placeholder="Стоимость"
                    small
                    onChange={(val) => this.setState({ price: val })}
                  />

                  <UiTextInput
                    placeholder="XML ID"
                    small
                    onChange={(val) => this.setState({ xml_id: val })}
                  />

                  <UiCheckItem
                    label="Подарки в корзине"
                    defaultValue={this.state.cart_recommendation}
                    onChange={(val) => {
                     
                      if (val) this.setState({ cart_recommendation: 1 }); else this.setState({ cart_recommendation: 0 });
                    }}
                  />

                  <label className="edit-image-button">
                    <b>Добавить изображения</b>
                    <input
                      type="file"
                      multiple="multiple"
                      style={{ display: "none" }}
                      onChange={(e) => {
                       
                        if (e.target.files.length > 0) {
                          for(let i = 0; i < e.target.files.length; i++){
                            let arr = this.state.savedImages;
                            this.getBase64(e.target.files[i], (result) => {
                              arr.push(result);
                              this.setState({
                                savedImages: arr,
                                base64Image: result,
                                isDialogCrop: true,
                              });
                            });
                          }
                        }
                      }}
                    />
                  </label>

                  <div className="modal-catalog-form-images">
                    <label className="inter-add-image">{images}</label>
                  </div>

                  <br />

                  <UiButtonOrange
                    buttonText="Добавить"
                    small
                    onClick={() => this.addFlower()}
                  />
                </div>
              </div>
            </div>
          </div>
          <UiFooter />
        </div>

        {/* modals */}
        <UiLoader modalOpen={this.state.loader} />

        <UiModalImageCrop
          modalOpen={this.state.isDialogCrop}
          base64Image={this.state.savedImages[0]}
          onSave={(val) => this._onSaveImage(val)}
          cancelClick={() => this.setState({ isDialogCrop: false })}
        />
      </div>
    );
  }
}

export default AddFlowerPage;
