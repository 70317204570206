import React, { Component } from "react";

import "./Catalog.scss";

import UiButtonOrange from "../../components/buttons/UiButtonOrange";
import UiFooter from "../../components/footer/UiFooter";
import UiHeader from "../../components/header/UiHeader";
import UiLoader from "../../components/modals/UiLoader";
import UiModalImageCrop from "../../components/modals/UiModalImageCrop";
import UiTextInput from "../../components/forms/UiTextInput";
import UiTextArea from "../../components/forms/UiTextArea";
import UiSelect from "../../components/forms/UiSelect";
import UiCheckItem from "../../components/forms/UiCheckItem";

import Env from "../../services/Env";
import { loadUser } from "../../services/Auth";
import { edit } from "../../services/Chapters";
import getCroppedImg from "../../services/CropImage";
import { addFile, addFileBase64, retrieveData } from "../../services/Storage";

class EditChapterPage extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        data: null,
        image: null,
        title: "",
        description: "",
        composition: "",
        category: 0,
        type: 0,
        chapter: null,
        size: 0,
        price: 0,
        cart_recommendation: 0,

        parentsList: [],
        parents2List: [],
        parents3List: [],

        /* modals */
        loader: false,
        xml_id: null
    };

    componentDidMount() {
        this._load();
    }

    _load() {
        let _user = loadUser();
        let _list = retrieveData("chapters");
        let arr = [];
        let arr2 = [];
        let arr3 = [];

        _list.map((item) => {
            if (item.type == 0) arr.push({ option: item.title, value: item.id });
            if (item.type == 1) arr2.push({ option: item.title, value: item.id });
            if (item.type == 2) arr3.push({ option: item.title, value: item.id });
        })
        console.log(arr);

        let _data = retrieveData("chapterEdit");
        console.log(_data);
        if (_data) {
            this.setState({ data: _data });
        }

        this.setState({
            user: _user,
            parentsList: arr,
            parents2List: arr2,
            parents3List: arr3,
        });
    }

    _edit(id, val) {
        var _data = this.state.data;
        _data[id] = val;
        this.setState({ data: _data });
      }
    

    _onSaveImage(_croppedAreaPixels) {
        this.setState({ isDialogCrop: false });
        getCroppedImg(this.state.base64Image, _croppedAreaPixels, 0).then((res) => {
            this.setState(
                {
                    selectedFileName: res,
                },
                () => {
                    this.upload();
                }
            );
        });
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }

    upload(e) {
        this.setState({ imageLoad: true });
        addFileBase64(this.state.selectedFileName, 0).then((res) => {
            this.setState({ imageLoad: false });
            if (res.success) {
                this._edit("image", res.response)
               
            } else {
                console.log(res);
            }
        });
    }

    edit() {
        console.log(
            this.state.user.api_token,
            this.state.title,

            this.state.image,

        );
        if (
            this.state.data.title != ""
        ) {
            edit(
                this.state.user.api_token,
                this.state.data.id,
                this.state.data.title,
                this.state.data.type,
                this.state.data.parent_chapter,
                this.state.data.image,
            ).then((res) => {
                console.log(res);
                if (res.response) {
                     
                    this.props.history.push("/chapters");
                }
            });
        } else {
        }
    }

    render() {


        return (
            <div ref={this.divPage} id="page" className="edit-flower-page">
                <UiHeader page="catalog" />
                {this.state.data ?
                    <div className="content">
                        <div className="section">
                            <div className="users-table-wrap">
                                <h2>Редактировать раздел:</h2>
                                <div className="add-content">
                                    <div className="modal-catalog-form">
                                        <UiTextInput
                                            placeholder="Название"
                                            small
                                            value={this.state.data.title}
                                            onChange={(val) =>  this._edit("title", val) }
                                        />

                                        <UiSelect
                                            label="Тип"
                                            selected={this.state.data.type}
                                            onChange={(val) => {
                                                this.setState({ type: val });
                                                this._edit("type", val);
                                                if (val != 0) {
                                                    this._edit("parent_chapter", this.state.parentsList[0].value);
                                                    this.setState({ chapter: this.state.parentsList[0].value })
                                                }
                                            }}
                                            optionList={[

                                                { option: "Подраздел", value: 0 },
                                                { option: "Категория", value: 1 },
                                                { option: "Подкатегория", value: 2 },

                                            ]}
                                        />

                                        {this.state.type == 1 ?
                                            <UiSelect
                                                label="Родительская категория"
                                                onChange={(val) =>  this._edit("parent_chapter", val) }
                                                optionList={this.state.parentsList}
                                            />
                                            : null}

                                        {this.state.type == 2 ?
                                            <UiSelect
                                                label="Родительская категория"
                                                onChange={(val) => this._edit("parent_chapter", val) }
                                                optionList={this.state.parents2List}
                                            />
                                            : null}


                                        <label className="edit-image-button">
                                            <b>Изменить изображения</b>
                                            <input
                                                type="file"
                                                style={{ display: "none" }}
                                                onChange={(e) => {
                                                    if (e.target.files.length > 0) {
                                                        this.getBase64(e.target.files[0], (result) => {
                                                            this.setState({
                                                                base64Image: result,
                                                                isDialogCrop: true,
                                                            });
                                                        });
                                                    }
                                                }}
                                            />
                                        </label>

                                        <div className="modal-catalog-form-images">
                                            <label className="inter-add-image">
                                                {this.state.data.image ?
                                                    <img src={Env.PUBLIC_URL + this.state.data.image} className="image" />
                                                    : null}
                                            </label>
                                        </div>

                                        <br />

                                        <UiButtonOrange
                                            buttonText="Сохранить"
                                            small
                                            onClick={() => this.edit()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <UiFooter />
                    </div>
                    : null}

                {/* modals */}
                <UiLoader modalOpen={this.state.loader} />

                <UiModalImageCrop
                    modalOpen={this.state.isDialogCrop}
                    base64Image={this.state.base64Image}
                    onSave={(val) => this._onSaveImage(val)}
                    cancelClick={() => this.setState({ isDialogCrop: false })}
                />
            </div>
        );
    }
}

export default EditChapterPage;
