import React, { Component } from "react";

import "./UiModal.scss";

import { ReactComponent as SvgCloseIcon } from "../../assets/images/ui/close.svg";

class UiModalCloseButton extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button onClick={this.props.onClick} className="modal-close-button">
        <SvgCloseIcon className="modal-close-button-icon" />
      </button>
    );
  }
}

export default UiModalCloseButton;
