import React, { Component } from "react";
import ReactDOM from "react-dom";

import "./UiModal.scss";

class UiModal extends Component {
  state = {
    modalVisible: false,
    topScrollConst: 0,
  };

  constructor(props) {
    super(props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.modalOpen && !this.state.modalVisible) {
      this.setState({ modalVisible: true }, () => {
        this._openModal();
      });
    } else if (!this.props.modalOpen && this.state.modalVisible) {
      this.setState({ modalVisible: false }, () => {
        this._closeModal();
      });
    }

    if (this.props.modalOpen != prevProps.modalOpen) {
      if (document.getElementById("modal-inner")) {
        let windowHeight = window.innerHeight;
        let modalHeight = document.getElementById("modal-inner").offsetHeight;
        this.setState({
          checkVerticalScroll: modalHeight > windowHeight,
        });
      }
    }
  }

  _openModal = () => {
    let top = document.getElementById("page").scrollTop;
    console.log(top);
    this.setState(
      {
        modalVisible: true,
        topScrollConst: top,
      },
      () => {
        let element = document.getElementById("page");
        element.classList.toggle("page-lock");
        ReactDOM.findDOMNode(element).style.top = -top + "px";
      }
    );
  };

  _closeModal = () => {
    let element = document.getElementById("page");
    element.classList.toggle("page-lock");
    this.setState({ modalVisible: false }, () => {
      document.getElementById("page").scrollTo({
        top: this.state.topScrollConst,
      });
    });
  };

  render() {
    return (
      <div className="modal-wrap">
        {this.props.modalOpen ? (
          <div
            className={
              "modal" +
              (this.props.modalOpen ? " show" : " hide") +
              (this.state.checkVerticalScroll ? " scroll" : "")
            }
          >
            <div
              className={"modal-inner" + " " + this.props.modal}
              id="modal-inner"
            >
              {this.props.modalInner}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default UiModal;
