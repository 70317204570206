import React, { Component } from 'react';

import './UiSearchInput.scss';

import { Icon } from '@iconify/react';
import searchIcon from '@iconify-icons/zmdi/search';

class UiSearchInput extends Component {

  state = {
  }

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="search-input-wrap">
        <input
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          type={this.props.type}
          value={this.props.value}
          className="search-input"
          onChange={e => this.props.callBack(e.target.value)}
        />
        <button className="search-input-button">
          <Icon icon={searchIcon} />
        </button>
      </div>
    );
  }
}
export default UiSearchInput;