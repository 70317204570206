import ENV from '../services/Env.js';

export const getAllGrouped = () => {
  return fetch(ENV.API_URL + '/chapters/get/grouped/all', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    } 
  }).then((response) => {
    return response.json()
  });
}



export const getAll = (_token) => {
  return fetch(ENV.API_URL + '/chapters/all', {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    } 
  }).then((response) => {
    return response.json()
  });
}

export const getChaptersByFlower = (_id) => {
  return fetch( `${ENV.API_URL}/chapters/get/flower/${_id}`, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    } 
  }).then((response) => {
    return response.json()
  });
}

export const add = (_api, _title, _type, _chapter, _image ) => {
 
  return fetch(ENV.API_URL+'/chapters/add', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_token: _api,
        title: _title,  
        type: _type,  
        chapter: _chapter,  
        image: _image,
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const edit = (_api, _id, _title, _type, _chapter, _image ) => {
 
  return fetch(ENV.API_URL+'/chapters/edit', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_token: _api,
        id: _id,
        title: _title,  
        type: _type,  
        chapter: _chapter,  
        image: _image,
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const remove = (_api, _id ) => {
 
    return fetch(ENV.API_URL+'/chapters/remove', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          api_token: _api,
          id: _id,
   
        }) 
      }).then(function(response) {
        return response.json();
      });
  }
  
  export const block = (_api, _id ) => {
   
    return fetch(ENV.API_URL+'/chapters/block', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          api_token: _api,
          id: _id,
   
        }) 
      }).then(function(response) {
        return response.json();
      });
  }
  
  export const addFlowerToChapter = (_api, _flower, _category ) => {
   
    return fetch(ENV.API_URL+'/chapters/addflower', {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          api_token: _api,
          flower: _flower,
          category: _category,
        }) 
      }).then(function(response) {
        return response.json();
      });
  }
  