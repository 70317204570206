import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import * as serviceWorker from './serviceWorker';

/* base stylesheet */
import './styles/index.scss';

/* google font Roboto */
var WebFont = require('webfontloader');

WebFont.load({
  google: {
    families: ['Roboto:100,300,400,500,700,900']
  }
});

const clientRender = () => {
  ReactDOM.render(
    <Router>
      <App />
    </Router>,
    document.getElementById('root')
  );
};

serviceWorker.unregister();

const serverRender = () => {};

if (typeof window === 'object') {
  clientRender();
} else {
  serverRender();
};