import React, { Component } from "react";

import "./UiSelect.scss";

import { ReactComponent as SelectDownIcon } from "../../assets/images/ui/select-down.svg";

class UiSelectButton extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    activeOption: "",
    selectValue: null,
  };

  componentDidMount() {
    //if( this.props.optionList[0]) this.setState({ activeOption: this.props.optionList[0].option })
  }

 

  render() {
    return (
      <div className={"select-wrap" + (this.props.green ? " green" : "") + (this.props.pink ? " pink" : "")}>
        <label>{this.props.label}</label>
        <div className="select-container">
          <button className="select" onClick={this.props.onClick} >{this.props.buttonText}</button>
        </div>
      </div>
    );
  }
}
export default UiSelectButton;
