
export const saveUser = (_user) => {
  localStorage.setItem("mfa_user", JSON.stringify(_user) );
}

export const loadUser = (_user) => {
 let user = localStorage.getItem("mfa_user");
 if(user != null && user != undefined) user = JSON.parse(user); 
 return user;
}


export const isAuthenticated = () => {
  if (localStorage.getItem("mfa_user") == null) {
    return false;
  } else {
    return true;
  }
};

export const logout = () => {
  if (localStorage.getItem("mfa_user") != null) {
    localStorage.removeItem("mfa_user");
  }
};
