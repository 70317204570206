import React, { Component } from "react";

import "./Archive.scss";

import UiButtonRound from "../../components/buttons/UiButtonRound";
import UiButtonSort from "../../components/buttons/UiButtonSort";
import UiFooter from "../../components/footer/UiFooter";
import UiHeader from "../../components/header/UiHeader";
import UiLoader from "../../components/modals/UiLoader";
import UiSearchInput from "../../components/forms/UiSearchInput";
import UiPaginator from "../../components/bars/UiPaginator";

import { getAll } from "../../services/Deals";
import { loadUser } from "../../services/Auth";
import { formatDate } from "../../components/common/Dates";
import { storeData, safeJsonParse } from "../../services/Storage";

class ArchivePage extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    activeVisible: false,
    colSettingsActive: false,
    loader: false,
    searchLine: "",
    user: null,
    currentPage: 1,
    lastPage: 1,
    firstPage: 1,

    dealsList: [],
  };

  componentDidMount() {
    this._load();
  }

  _search(val) {
    this.setState({ searchLine: val });
  }

  _checkInclude(substring) {
    if (substring) {
      if (this.state.searchLine) {
        let str = this.state.searchLine;
        return (
          substring.toString().toLowerCase().indexOf(str.toLowerCase()) !== -1
        );
      }
    }
  }

  _load() {
    let _user = loadUser();
    this.setState({
      user: _user,
      loader: true,
    });

    getAll(_user.api_token,  this.state.currentPage, "archive").then((res) => {
      let arr = [];
      if (res.response) {
        res.response.data.map((item) => {
            arr.push(item);
        });
        this.setState({
          lastPage: res.response.last_page, 
          dealsList: arr,
          loader: false
        });
      } else {
        this.props.history.push("/login");
      }
    });
  }

  render() {
    let deals = this.state.dealsList.map((item, index) => {
      if (
        this.state.searchLine == "" ||
        (this.state.searchLine != "" &&
          (this._checkInclude(item.id) ||
            this._checkInclude(item.user.name) ||
            this._checkInclude(item.user.phone)))
      ) {
        return (
          <tr key={index}>
            <td>{item.id}</td>
            <td>
              <span style={{ fontWeight: 700 }}>
                {item.status == 0 ? "новый" : ""}
                {item.status == 1 ? "подтвержден" : ""}
                {item.status == 2 ? "букет в работе" : ""}
                {item.status == 3 ? "букет готов" : ""}
                {item.status == 4 ? "доставляют" : ""}
                {item.status == 5 ? "выполнен" : ""}
                {item.status == 6 ? "отменен" : ""}
              </span>
              {" | "}
              {item.is_pay == 0 ? <span style={{ fontWeight: 700, color: 'red' }}>  не оплачен  </span> : ""}
              {item.is_pay == 1 ? <span style={{ fontWeight: 700, color: 'green' }}>оплачен </span> : ""}
            </td>
            <td>{formatDate(item.created_at)}</td>
            <td>{item.price}</td>
            <td>
              {item.order_id == null ? "оплата курьеру" : ""}
              {item.order_id != null ? "онлайн" : ""}
            </td>
            <td>{item.delivery_address}</td>
            <td>{item.delivery_date}</td>
            <td>{item.delivery_time}</td>
            <td>{item.user ? item.user.name : null}</td>
            <td>
              {item.user ?
                <a href={"tel:" + item.user.phone}>{item.user.phone}</a>
                : null}
            </td>
            <td>{item.additional_settings.callBeforeDelivery ? 'да' : 'нет'}</td>
            <td>
              <div>
                <UiButtonRound icon="open" onClick={
                  () => {

                    storeData("orderEdit", item);
                    this.props.history.push("/order");
                  }
                } />
                <UiButtonRound icon="delete" />
              </div>
            </td>
          </tr>
        );
      }
    });

    return (
      <div
        ref={this.divPage}
        id="page"
        className={
          "archive-page" + (this.state.modalVisible ? " page-lock" : "")
        }
      >
        <UiHeader page="archive" />
        <div className="content">
          <div className="section">
            <div className="users-table-wrap">
              <h2>Архив заказов:</h2>
              <UiSearchInput
                placeholder="Найти"
                callBack={(line) => this._search(line)}
              />
              {/* Таблица заказов */}
              <table className="table users-table">
                <tbody>
                  <tr>
                    <th>
                      <UiButtonSort title="№" />
                    </th>
                    <th>
                      <UiButtonSort title="Статус" />
                    </th>
                    <th>
                      <UiButtonSort title="Дата заказа" />
                    </th>
                    <th>
                      <UiButtonSort title="Стоимость" />
                    </th>
                    <th>
                      <UiButtonSort title="Оплата" />
                    </th>
                    <th>
                      <UiButtonSort title="Адрес доставки" />
                    </th>
                    <th>
                      <UiButtonSort title="Дата доставки" />
                    </th>
                    <th>
                      <UiButtonSort title="Время доставки" />
                    </th>
                    <th>
                      <UiButtonSort title="Покупатель" />
                    </th>
                    <th>
                      <UiButtonSort title="Телефон" />
                    </th>
                    <th>
                      <UiButtonSort title="Звонок" />
                    </th>
                    <th></th>
                  </tr>
                  {deals.length > 0 ? (
                    deals
                  ) : (
                    <tr>
                      <td colSpan={11}>Нет завершенных заказов</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>

          {/* paginator */}
          <UiPaginator
            onChange={(val) => {
              this.setState({
                currentPage: val
              }, () => this._load())
            }}
            lastPage={this.state.lastPage}
            currentPage={this.state.currentPage}
          />

          <UiFooter />
        </div>

        {/* modals */}
        <UiLoader modalOpen={this.state.loader} />
      </div>
    );
  }
}

export default ArchivePage;
