import ENV from '../services/Env.js';

export const getAll = (_token, _page = 1) => {
 
  return fetch(ENV.API_URL + '/flowers/get/full', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _token,
      page: _page,
    }),
  }).then((response) => {
    return response.json()
  });
}

export const getAllGifts = (_token) => {
 
  return fetch(ENV.API_URL + '/flowers/get/fullgift', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      api_token: _token,
    }),
  }).then((response) => {
    return response.json()
  });
}


 

export const GetSubCategories = () => {

  return fetch(ENV.API_URL + '/categories/sub/all', {
      method: 'GET',
  }).then(function (response) {
      return response.json();
  });
}



 

export const add = (_api, _title,  _description, _composition, _category, _type,  _size, _price, _cart_recommendation, _images, _xml_id) => {
 
  return fetch(ENV.API_URL+'/flowers/add', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_token: _api,
        title: _title,  
        description: _description, 
        composition: _composition, 
        category: _category, 
        type: _type,  
        size: _size, 
        price: _price, 
        cart_recommendation: _cart_recommendation,
        images: _images,
        xml_id: _xml_id
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const edit = (_api, _id, _title,  _description, _composition, _category, _sub_category, _type,  _size, _price, _cart_recommendation, _images, _sort, _remove, _xml_id) => {
  return fetch(ENV.API_URL+'/flowers/edit', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_token: _api,
        id: _id,
        title: _title,  
        description: _description, 
        composition: _composition, 
        category: _category, 
        sub_category: _sub_category,
        type: _type,  
        size: _size, 
        price: _price, 
        sort: _sort,
        cart_recommendation: _cart_recommendation,
        images: _images,
        remove: _remove,
        xml_id: _xml_id
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const removeFlower = (_api, _id ) => {
 
  return fetch(ENV.API_URL+'/flowers/remove', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_token: _api,
        id: _id,
 
      }) 
    }).then(function(response) {
      return response.json();
    });
}

export const blockFlower = (_api, _id ) => {
 
  return fetch(ENV.API_URL+'/flowers/block', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        api_token: _api,
        id: _id,
 
      }) 
    }).then(function(response) {
      return response.json();
    });
}



export const getTags = () => {

  return fetch(ENV.API_URL + '/flowers/tags/all', {
      method: 'GET',
  }).then(function (response) {
      return response.json();
  });
}


export const editTags = (_item) => {
  return fetch(ENV.API_URL+'/flowers/tags/edit', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(_item) 
    }).then(function(response) {
      return response.json();
    });
}