import React, { Component } from 'react';

import './UiButtonMenu.scss';

import menuIcon from '../../assets/images/ui/menu.svg';

class UiButtonMenu extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <button className="menu-button" onClick={() => this.props.onClick()}>
        <img className="menu-button-icon" src={menuIcon} />
      </button>
    );
  }
}
export default UiButtonMenu;