import React, { Component } from 'react';

import './UiTextArea.scss';

class UiTextArea extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="textarea-wrap">
        <label>{this.props.label}</label>
        <textarea className="textarea" placeholder={this.props.placeholder} value={this.props.value} onChange={(e) => this.props.onChange(e.target.value)} ></textarea>
      </div>
    );
  }
}
export default UiTextArea;