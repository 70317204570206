import React, { Component } from 'react';

import './UiCheckItem.scss';

import { Icon } from '@iconify/react';
import checkIcon from '@iconify-icons/zmdi/check';


class UiCheckItem extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    radioActive: false,
  }

  componentDidMount(){
    if(this.props.defaultValue !== undefined){
      this.setState({ radioActive: this.props.defaultValue });
    }
  }

  radioCheck() {
    this.setState({ radioActive: !this.state.radioActive }, () => this.props.onChange(this.state.radioActive) );
  }

  render() {
    return (
      <button
        className="check-item"
        onClick={() => this.radioCheck()}
      >
        <div className={"check-item-icon" + (this.state.radioActive ? " active" : "")}>
          <Icon className="check-item-icon-check" icon={checkIcon} />
        </div>
        <span>{this.props.label}</span>
      </button>
    );
  }
}
export default UiCheckItem;