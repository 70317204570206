import React, { Component } from "react";
import { Redirect } from "react-router";

import UiButtonOrange from "../../components/buttons/UiButtonOrange";
import UiTextInput from "../../components/forms/UiTextInput";

import "./Login.scss";

import { checkLogin } from "../../services/User";
import { saveUser } from "../../services/Auth";

class LoginPage extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    modalVisible: false,
    redirectPage: "",

    login: "",
    pass: "",
  };

  componentDidMount() {
    this._load();
  }

  _load() {}

  navigate = (value) => {
    this.setState({ redirect: true, redirectPage: value });
  };

  login() {
    if (this.state.login.length > 3 && this.state.pass.length > 3) {
      checkLogin(this.state.login, this.state.pass).then((res) => {
        if (res.response) {
          if (res.response.api_token) {
            console.log(res);
            saveUser(res.response);
            this.navigate("");
          }
        } else {
          alert("Пользователь не найден");
        }
      });
    } else {
      alert("Введите логин  пароль");
    }
  }

  render() {
    if (this.state.redirect) {
      return <Redirect push to={"/" + this.state.redirectPage} />;
    }
    return (
      <div
        ref={this.divPage}
        id="page"
        className={"login-page" + (this.state.modalVisible ? " page-lock" : "")}
      >
        <div className="login-form">
          <div className="login-form-input">
            <UiTextInput
              label="Логин *"
              onChange={(res) => this.setState({ login: res })}
              value={this.state.login}
            />
          </div>
          <div className="login-form-input">
            <UiTextInput
              label="Пароль *"
              onChange={(res) => this.setState({ pass: res })}
              type="password"
              value={this.state.pass}
            />
          </div>
          <UiButtonOrange buttonText="Вход" onClick={() => this.login()} />
        </div>
        <div className="login-logo">
          <h2>MATE flowers</h2>
          <p>Панель администрирования</p>
        </div>
      </div>
    );
  }
}

export default LoginPage;
