import React, { Component } from "react";

import "./UiModal.scss";

import UiModal from "./UiModal";

class UiAlert extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const modalInner = (
      <div className="inter-alert-overlay">
        <div className="inter-alert-body">
          <h3>{this.props.title}</h3>
          {this.props.text ? <p>{this.props.text}</p> : null}
          <div className="inter-alert-body-buttons">
            <button onClick={() => this.props.onClick() } >Да</button>
            <button onClick={() => this.props.cancelClick() }>Нет</button>
          </div>
        </div>
      </div>
    );
    return (
      <UiModal
        modal="alert"
        modalInner={modalInner}
        modalOpen={this.props.modalOpen}
      />
    );
  }
}

export default UiAlert;
