import React, { Component } from "react";

import "./Catalog.scss";

import UiAlert from "../../components/modals/UiAlert";
import UiButtonOrange from "../../components/buttons/UiButtonOrange";
import UiButtonRound from "../../components/buttons/UiButtonRound";
import UiButtonSort from "../../components/buttons/UiButtonSort";
import UiFooter from "../../components/footer/UiFooter";
import UiHeader from "../../components/header/UiHeader";
import UiLoader from "../../components/modals/UiLoader";
import UiModalCatalogEdit from "../../components/modals/UiModalCatalogEdit";
import UiSearchInput from "../../components/forms/UiSearchInput";

import Env from "../../services/Env";
import { getAll, remove, block, getAllGrouped, getChaptersByFlower } from "../../services/Chapters";
import { storeData } from "../../services/Storage";
import { loadUser } from "../../services/Auth";

class ChaptersPage extends Component {

  constructor(props) {
    super(props);
  }

  state = {
    searchLine: "",
    catalogList: [],
    catalogListUngrouped: [],
    typesList: [
      "Подраздел",
      "Категория",
      "Подкатегория",
    ],
    /* modals */
    loader: false,
    isAlertBlock: false,
    isAlert: false,
    modalCatalogActive: false,
    modalCatalogEditActive: false,
  };

  componentDidMount() {
    this._load();
  }

  _search(val) {
    this.setState({ searchLine: val });
  }

  _checkInclude(substring) {
    if (substring) {
      if (this.state.searchLine) {
        let str = this.state.searchLine;
        return (
          substring.toString().toLowerCase().indexOf(str.toLowerCase()) !== -1
        );
      }
    }
  }

  _load() {
    let _user = loadUser();
    this.setState({
      user: _user,
      loader: true,
    });

    getAllGrouped().then((res) => {
      console.log(" getAllGrouped ", res);
    })

    getAll(_user.api_token).then((res) => {

      this.setState({ loader: false });
      let arr = [];
      console.log(res);
      if (res.response) {
        let grouped = this._groupBy(res.response, "parent_chapter");
        this.setState({ catalogListUngrouped: res.response, catalogList: grouped });

        let arr = [];
        res.response.map((item) => {
          //if(item.type != 0 ){
          arr.push(item);
          // }
        })

        console.log(grouped);

        storeData("chapters", arr);
      } else {
        this.props.history.push("/login");
      }
    });
  }

  _getChapterName(_id) {
    let title = null;
    this.state.catalogListUngrouped.map((item) => {
      if (item.id == _id) title = item.title;
    })
    return title;
  }

  _groupBy(array, key) {
    let finalArray = [];
    array.forEach(function (element) {
      var newArray = [];
      array.forEach(function (element1) {
        if (element[key] == element1[key]) {
          newArray.push(element1);
        }
      });
      if (!finalArray.some((arrVal) => newArray[0][key] == arrVal[0][key])) {
        finalArray.push(newArray);
      }
    });
    //console.log(array, finalArray)
    return finalArray;
  }

  remove() {
    remove(this.state.user.api_token, this.state.selectedId).then(
      (res) => {
        console.log(res);
        this.setState({ isAlert: false });
        this._load();
      }
    );
  }

  block() {
    block(this.state.user.api_token, this.state.selectedId).then(
      (res) => {
        console.log(res);
        this.setState({ isAlertBlock: false });
        this._load();
      }
    );
  }


  render() {



    let groupedList = this.state.catalogList.map((group, gIndex) => {

      let catalog = group.map((item, index) => {
        if (
          this.state.searchLine == "" ||
          (this.state.searchLine != "" &&
            (
              this._checkInclude(item.id) || this._checkInclude(item.title)
            ))
        ) {
          return (
            <tr key={index}>
              <td>
                {item.title}
                {item.parent_chapter ?
                  ` ( ${this._getChapterName(item.parent_chapter)} )`
                  : null}
              </td>
              <td>{this.state.typesList[item.type]}</td>
              <td>
                {item.image ? (
                  <img src={Env.PUBLIC_URL + item.image} className="image" />
                ) : (
                  "Нет изображения"
                )}
              </td>
              <td>
                <div>
                  <UiButtonRound
                    icon="open"
                    onClick={() => {
                      storeData("chapterEdit", item);
                
                      this.props.history.push("/editchapter");
                    }}
                  />
                  <UiButtonRound
                    icon={item.is_block ? "lock" : "lock-open"}
                    onClick={() => this.setState({ selectedId: item.id, isAlertBlock: true })}
                  />
                  <UiButtonRound
                    icon="delete"
                    onClick={() =>
                      this.setState({ isAlert: true, selectedId: item.id })
                    }
                  />
                </div>
              </td>
            </tr>
          );
        }
      });

      return (


        <table key={gIndex} className="table users-table">
          <h3>{this._getChapterName(group[0].parent_chapter)}</h3>
          <tbody>
            <tr>
              <th>
                <UiButtonSort sortStart={true} title="Название" />
              </th>
              <th>
                <UiButtonSort sortStart={false} title="Тип" />
              </th>

              <th>Изображения</th>
              <th></th>
            </tr>

            {catalog}
          </tbody>
        </table>

      )
    })

    return (
      <div ref={this.divPage} id="page">
        <UiHeader page="chapters" />
        <div className="content">
          <div className="section">
            <div className="users-table-wrap">
              <h2>Список разделов:</h2>
              <UiSearchInput
                placeholder="Найти"
                callBack={(line) => this._search(line)}
              />
              <div className="add-button-wrap">
                <UiButtonOrange
                  buttonText="Добавить раздел"
                  onClick={() => {
                    storeData("referPage", "chapters");
                    this.props.history.push("/addchapter");
                  }}
                  small
                />
              </div>

              {groupedList}
            </div>
          </div>
          <UiFooter />
        </div>

        {/* modals */}
        <UiLoader modalOpen={this.state.loader} />

        <UiAlert
          modalOpen={this.state.isAlert}
          title="Внимание!"
          text="Вы не сможете отменить удаление. Продолжить?"
          onClick={() => this.remove()}
          cancelClick={() => this.setState({ isAlert: false })}
        />

        <UiAlert
          modalOpen={this.state.isAlertBlock}
          title="Заблокировать?"
          text="Раздел не будет отображаться. Продолжить?"
          onClick={() => {
            this.block();
          }}
          cancelClick={() => this.setState({ isAlertBlock: false })}
        />

        <UiModalCatalogEdit
          modalOpen={this.state.modalCatalogEditActive}
          modalClose={() => this.setState({ modalCatalogEditActive: false })}
        />
      </div>
    );
  }
}

export default ChaptersPage;
