import React, { Component } from "react";

import "./Order.scss";
import { Icon } from "@iconify/react";

import UiCardInfo from "../../components/cards/UiCardInfo";
import UiCardOrderItem from "../../components/cards/UiCardOrderItem";
import UiFooter from "../../components/footer/UiFooter";
import UiHeader from "../../components/header/UiHeader";
import UiLoader from "../../components/modals/UiLoader";

import { loadUser } from "../../services/Auth";
import { formatDate } from "../../components/common/Dates";
import { getAll, removeFlower } from "../../services/Flower";
import { retrieveData, safeJsonParse } from "../../services/Storage";
import Env from "../../services/Env";

class OrderPage extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    activeVisible: false,
    colSettingsActive: false,

    catalogList: [],
    item: null
  };


  componentDidMount() {
    this._load();
  }

  _load() {
    let _user = loadUser();
    this.setState({
      user: _user,
    });

    let _data = retrieveData("orderEdit");

    if (_data) {
      _data.compositions = safeJsonParse(_data.compositions);
      console.log(_data)
      this.setState({ item: _data });
    }

    getAll(_user.api_token).then((res) => {

      this.setState({ loader: false });
      let arr = [];
      if (res.response) {
        res.response.map((item) => {

          if (item.imgs) item.imgs = item.imgs.split(",");
          else item.imgs = [];
          arr.push(item);

        });
        this.setState({ catalogList: arr });
      } else {
        this.props.history.push("/login");
      }
    });

  }

  _getFlower(_id) {
    let val = null;
    this.state.catalogList.map((item) => {

      if (item.id == _id) val = item;
    })

    return val;
  }


  render() {
    if (this.state.item) {
      let list = this.state.item.compositions.map((item, index) => {
        return (
          <UiCardOrderItem
            key={index}
            image={this._getFlower(item.id) ?
              Env.PUBLIC_URL + this._getFlower(item.id).imgs[0]
              : null
            }
            title={item.title}
            text={this._getFlower(item.id) ?
              this._getFlower(item.id).description
              : null
            }
            num={item.count}
            price={this._getFlower(item.id) ?
              this._getFlower(item.id).price
              : null
            }
          />
        )
      })

      return (
        <div
          ref={this.divPage}
          id="page"
          className={"order-page" + (this.state.modalVisible ? " page-lock" : "")}
        >
          <UiHeader page="order" />
          <div className="content">
            <div className="section">
              <div className="order-wrap">
                <div className="order-title-wrap">
                  {/*<button className="order-title-back" onClick={() => {
                   this.props.history.push("/");
                 }}>
                    <Icon icon={arrowLeft} /> Назад
                </button> */}
                  <h2>Заказ №11012021-001</h2>
                </div>
                <div className="order">
                  <UiCardInfo label="Статус заказа" text={
                    this.state.item.status == 0 ? "новый" :
                      this.state.item.status == 1 ? "подтвержден" :
                        this.state.item.status == 2 ? "букет в работе" :
                          this.state.item.status == 3 ? "букет готов" :
                            this.state.item.status == 4 ? "доставляют" :
                              this.state.item.status == 5 ? "выполнен" :
                                this.state.item.status == 6 ? "отменен" : ""
                  } />
                  <UiCardInfo label="Статус оплаты" text={
                    this.state.item.is_pay == 0 ? "не оплачен" :
                      this.state.item.is_pay == 1 ? "оплачен" : ""
                  } />
                  <UiCardInfo label="Тип оплаты" text={
                    this.state.item.payment_type == 0 ? "Картой онлайн" :
                      this.state.item.payment_type == 1 ? "Наличка" : ""
                  }
                  />
                  <UiCardInfo label="Стоимость" text={this.state.item.price} />
                  <UiCardInfo label="Дата заказа" text={formatDate(this.state.item.created_at)} />
                  <UiCardInfo label="Дата доставки" text={this.state.item.delivery_date} />
                  <UiCardInfo label="Время доставки" text={this.state.item.delivery_time} />
                  <UiCardInfo
                    label="Адрес доставки"
                    text={this.state.item.delivery_address}
                  />
                  <UiCardInfo label="Заказчик" text={this.state.item.user.name} />
                  <UiCardInfo label="Телефон заказчика" text={this.state.item.user.phone} />
                  {this.state.item.additional_settings.receiverSettings.name ?
                    <UiCardInfo
                      label="Получатель"
                      text={this.state.item.additional_settings.receiverSettings.name}
                    /> : null}
                  {this.state.item.additional_settings.receiverSettings.phone ?
                    <UiCardInfo label="Телефон получателя" text={this.state.item.additional_settings.receiverSettings.phone} />
                    : null}
                  {this.state.item.additional_settings.image ?
                    <UiCardInfo label="Фотография пользователя" text={"ссылка"} link={this.state.item.additional_settings.image} />
                    : null}

                  <div className="order-composition">
                    <h3>Состав заказа:</h3>
                    {list}
                  </div>
                  <div className="order-option">
                    <h3>Выбранные опции:</h3>
                    <div className="order-option-wrap">
                      <div className="order-option-item">
                        <p>
                          <strong>
                            {this.state.item.additional_settings.callBeforeDelivery ? 'Звонок перед доставкой' : 'Звонок не нужен'}
                          </strong>
                        </p>
                      </div>
                      <div className="order-option-item">
                        <p>
                          <strong>
                            Число коробок  {this.state.item.additional_settings.boxCount}
                          </strong>
                        </p>
                      </div>
                    </div>
                    <h3>Комментарий к заказу:</h3>
                    <p className="order-option-text">{this.state.item.comment}</p>
                    <h3>Текст открытки:</h3>
                    <p className="order-option-text">{this.state.item.comment}</p>
                  </div>
                </div>
              </div>
            </div>
            <UiFooter />
          </div>

          {/* modals */}
          <UiLoader modalOpen={this.state.loader} />
        </div>
      );
    } else {
      return null;
    }

  }
}

export default OrderPage;
