import React, { Component } from "react";

import "./Catalog.scss";

import UiAlert from "../../components/modals/UiAlert";
import UiButtonOrange from "../../components/buttons/UiButtonOrange";
import UiButtonRound from "../../components/buttons/UiButtonRound";
import UiButtonSort from "../../components/buttons/UiButtonSort";
import UiFooter from "../../components/footer/UiFooter";
import UiHeader from "../../components/header/UiHeader";
import UiLoader from "../../components/modals/UiLoader";
import UiModalCatalogEdit from "../../components/modals/UiModalCatalogEdit";
import UiSearchInput from "../../components/forms/UiSearchInput";

import Env from "../../services/Env";
import { getAll, removeFlower, blockFlower } from "../../services/Flower";
import { storeData } from "../../services/Storage";
import { loadUser } from "../../services/Auth";

class SpecialPage extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    searchLine: "",
    catalogList: [],
    categoriesList: [
      "Обычные букеты",
      "Букеты невесты",
      "Мужские букеты",
      "Подарки",
      "Мастер класс",
      "Подписка на цветы",
      "Оформление мероприятий",
      "Цветочные боксы",
      "Спец категория",
    ],
    typesList: ["Нет", "Новинка", "Лимитированная коллекция", "Лидер продаж"],

    /* modals */
    loader: false,
    isAlertBlock: false,
    isAlert: false,
    modalCatalogActive: false,
    modalCatalogEditActive: false,
  };

  componentDidMount() {
    this._load();
  }

  _search(val) {
    this.setState({ searchLine: val });
  }

  _checkInclude(substring) {
    if (substring) {
      if (this.state.searchLine) {
        let str = this.state.searchLine;
        return (
          substring.toString().toLowerCase().indexOf(str.toLowerCase()) !== -1
        );
      }
    }
  }

  _load() {
    let _user = loadUser();
    this.setState({
      user: _user,
      loader: true,
    });

    getAll(_user.api_token).then((res) => {
      console.log(_user.api_token, res);
      this.setState({ loader: false });
      let arr = [];
      if (res.response) {
        res.response.map((item) => {
          if (item.category == 8) {
            if (item.imgs) item.imgs = item.imgs.split(",");
            else item.imgs = [];
            arr.push(item);
          }
        });
        this.setState({ catalogList: arr.reverse() });
      } else {
        this.props.history.push("/login");
      }
    });
  }

  remove() {
    removeFlower(this.state.user.api_token, this.state.selectedId).then(
      (res) => {
        console.log(res);
        this.setState({ isAlert: false });
        this._load();
      }
    );
  }


  block() {
    blockFlower(this.state.user.api_token, this.state.selectedId).then(
      (res) => {
        console.log(res);
        this.setState({ isAlertBlock: false });
        this._load();
      }
    );
  }


  render() {
    let catalog = this.state.catalogList.map((item, index) => {
      if (
        this.state.searchLine == "" ||
        (this.state.searchLine != "" &&
          (this._checkInclude(item.id) ||
            this._checkInclude(item.title) ||
            this._checkInclude(item.description) ||
            this._checkInclude(item.composition)))
      ) {
        return (
          <tr key={index}>
            <td>{item.title}</td>
            <td>
              {" "}
              <span className="small">{item.description} </span>{" "}
            </td>
            <td>{this.state.categoriesList[item.category]}</td>
            <td>{this.state.typesList[item.type]}</td>
            <td>{item.size}</td>
            <td>{item.price}</td>
            <td>
              {item.imgs.length > 0 ? (
                <img src={Env.PUBLIC_URL + item.imgs[0]} className="image" />
              ) : (
                "Нет изображения"
              )}
            </td>
            <td>
              <div>
                <UiButtonRound
                  icon="open"
                  onClick={() => {
                    storeData("referPage", "gift");
                    storeData("flowerEdit", item);
                    this.props.history.push("/editflower");
                  }}
                />
                <UiButtonRound
                  icon={item.is_block ? "lock" : "lock-open"}
                  onClick={() => this.setState({ selectedId: item.id, isAlertBlock: true })}
                />


                <UiButtonRound
                  icon="delete"
                  onClick={() =>
                    this.setState({ isAlert: true, selectedId: item.id })
                  }
                />
              </div>
            </td>
          </tr>
        );
      }
    });

    return (
      <div ref={this.divPage} id="page">
        <UiHeader page="special" />
        <div className="content">
          <div className="section">
            <div className="users-table-wrap">
              <h2>Список товаров:</h2>
              <UiSearchInput
                placeholder="Найти"
                callBack={(line) => this._search(line)}
              />
              <div className="add-button-wrap">
                <UiButtonOrange
                  buttonText="Добавить подарок"
                  onClick={() => {
                    storeData("referPage", "gift");
                    this.props.history.push("/addflower");
                  }}
                  small
                />
              </div>

              {/* Таблица товаров */}
              <table className="table users-table">
                <tbody>
                  <tr>
                    <th>
                      <UiButtonSort sortStart={true} title="Название" />
                    </th>
                    <th>Описание / Состав</th>
                    <th>
                      <UiButtonSort sortStart={true} title="Категория" />
                    </th>
                    <th>
                      <UiButtonSort sortStart={false} title="Тип" />
                    </th>
                    <th>
                      <UiButtonSort sortStart={false} title="Размер" />
                    </th>
                    <th>
                      <UiButtonSort sortStart={false} title="Стоимость" />
                    </th>
                    <th>Изображения</th>
                    <th></th>
                  </tr>

                  {catalog.length > 0 ? (
                    catalog
                  ) : (
                    <tr>
                      <td colSpan={8}>Нет добавленных товаров</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <UiFooter />
        </div>

        {/* modals */}
        <UiLoader modalOpen={this.state.loader} />

        <UiAlert
          modalOpen={this.state.isAlert}
          title="Внимание!"
          text="Вы не сможете отменить удаление. Продолжить?"
          onClick={() => this.remove()}
          cancelClick={() => this.setState({ isAlert: false })}
        />

        <UiAlert
          modalOpen={this.state.isAlertBlock}
          title="Заблокировать?"
          text="Товар не будет отображаться в каталоге. Продолжить?"
          onClick={() => this.block() }
          cancelClick={() => this.setState({ isAlertBlock: false })}
        />

        <UiModalCatalogEdit
          modalOpen={this.state.modalCatalogEditActive}
          modalClose={() => this.setState({ modalCatalogEditActive: false })}
        />
      </div>
    );
  }
}

export default SpecialPage;
