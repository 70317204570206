import React, { Component } from "react";

import "./UiTextInput.scss";

class UiTextInput extends Component {
  state = {};

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="text-input-wrap">
        <input
          disabled={this.props.disabled}
          placeholder={this.props.placeholder}
          type={this.props.type}
          value={this.props.value}
          className={"text-input" + (this.props.small ? " small" : "")}
          onChange={(e) => { if(this.props.onChange) this.props.onChange(e.target.value);  }}
          onBlur={(e) => { if(this.props.onBlur) this.props.onBlur(e.target.value);  }}
        />
        {this.props.label ? (
          <label className={this.props.value !== "" ? "filled" : ""}>
            <span>{this.props.label}</span>
          </label>
        ) : null}
      </div>
    );
  }
}
export default UiTextInput;
