import React, { Component } from "react";

import "./Catalog.scss";

import UiButtonOrange from "../../components/buttons/UiButtonOrange";
import UiFooter from "../../components/footer/UiFooter";
import UiHeader from "../../components/header/UiHeader";
import UiLoader from "../../components/modals/UiLoader";
import UiModalImageCrop from "../../components/modals/UiModalImageCrop";
import UiTextInput from "../../components/forms/UiTextInput";
import UiTextArea from "../../components/forms/UiTextArea";
import UiSelect from "../../components/forms/UiSelect";
import UiCheckItem from "../../components/forms/UiCheckItem";

import Env from "../../services/Env";
import { loadUser } from "../../services/Auth";
import { add } from "../../services/Chapters";
import getCroppedImg from "../../services/CropImage";
import { addFile, addFileBase64, retrieveData } from "../../services/Storage";

class AddChapterPage extends Component {
    constructor(props) {
        super(props);
    }

    state = {
        image: null,
        title: "",
        description: "",
        composition: "",
        category: 0,
        type: 0,
        chapter: null,
        size: 0,
        price: 0,
        cart_recommendation: 0,

        parentsList: [],
        parents2List: [],
        parents3List: [],

        /* modals */
        loader: false,
        xml_id: null
    };

    componentDidMount() {
        this._load();
    }

    _load() {
        let _user = loadUser();
        let _list = retrieveData("chapters");
        let arr = [];
        let arr2 = [];
        let arr3 = [];

        _list.map( (item) => {
           if(item.type == 0) arr.push( { option: item.title, value: item.id });
           if(item.type == 1) arr2.push( { option: item.title, value: item.id });
           if(item.type == 2) arr3.push( { option: item.title, value: item.id });
        } )
        console.log(arr);
        this.setState({
            user: _user,
            parentsList: arr,
            parents2List: arr2,
            parents3List: arr3,
        });
    }

    _onSaveImage(_croppedAreaPixels) {
        this.setState({ isDialogCrop: false });
        getCroppedImg(this.state.base64Image, _croppedAreaPixels, 0).then((res) => {
            this.setState(
                {
                    selectedFileName: res,
                },
                () => {
                    this.upload();
                }
            );
        });
    }

    getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            cb(reader.result);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }

    upload(e) {
        this.setState({ imageLoad: true });
        addFileBase64(this.state.selectedFileName, 0).then((res) => {
            this.setState({ imageLoad: false });
            if (res.success) {

                this.setState({ image: res.response });
            } else {
                console.log(res);
            }
        });
    }

    add() {
        console.log(
            this.state.user.api_token,
            this.state.title,

            this.state.image,

        );
        if (
            this.state.title != ""
        ) {
            add(
                this.state.user.api_token,
                this.state.title,
                this.state.type,
                this.state.chapter,
                this.state.image,
            ).then((res) => {
                console.log(res);
                if (res.response) {
                    let refer = "catalog";
                    if (retrieveData("referPage")) refer = retrieveData("referPage");
                    this.props.history.push("/" + refer);
                }
            });
        } else {
        }
    }

    render() {


        return (
            <div ref={this.divPage} id="page" className="edit-flower-page">
                <UiHeader page="catalog" />
                <div className="content">
                    <div className="section">
                        <div className="users-table-wrap">
                            <h2>Добавить раздел:</h2>
                            <div className="add-content">
                                <div className="modal-catalog-form">
                                    <UiTextInput
                                        placeholder="Название"
                                        small
                                        onChange={(val) => this.setState({ title: val })}
                                    />

                                    <UiSelect
                                        label="Тип"
                                        onChange={(val) => { 
                                            this.setState({ type: val });
                                            if( val != 0 ){
                                                this.setState({ chapter: this.state.parentsList[0].value })
                                            }
                                        }}
                                        optionList={[

                                            { option: "Подраздел", value: 0 },
                                            { option: "Категория", value: 1 },
                                            { option: "Подкатегория", value: 2 },

                                        ]}
                                    />

                                    {this.state.type == 1 ?
                                        <UiSelect
                                            label="Родительская категория"
                                            onChange={(val) => this.setState({ chapter: val })}
                                            optionList={ this.state.parentsList }
                                        />
                                    : null}

                                    {this.state.type == 2 ?
                                        <UiSelect
                                            label="Родительская категория"
                                            onChange={(val) => this.setState({ chapter: val })}
                                            optionList={ this.state.parents2List }
                                        />
                                    : null}


                                    <label className="edit-image-button">
                                        <b>Добавить изображения</b>
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={(e) => {
                                                if (e.target.files.length > 0) {
                                                    this.getBase64(e.target.files[0], (result) => {
                                                        this.setState({
                                                            base64Image: result,
                                                            isDialogCrop: true,
                                                        });
                                                    });
                                                }
                                            }}
                                        />
                                    </label>

                                    <div className="modal-catalog-form-images">
                                        <label className="inter-add-image">
                                            {this.state.image ?
                                                <img src={Env.PUBLIC_URL + this.state.image} className="image" />
                                                : null}
                                        </label>
                                    </div>

                                    <br />

                                    <UiButtonOrange
                                        buttonText="Добавить"
                                        small
                                        onClick={() => this.add()}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <UiFooter />
                </div>

                {/* modals */}
                <UiLoader modalOpen={this.state.loader} />

                <UiModalImageCrop
                    modalOpen={this.state.isDialogCrop}
                    base64Image={this.state.base64Image}
                    onSave={(val) => this._onSaveImage(val)}
                    cancelClick={() => this.setState({ isDialogCrop: false })}
                />
            </div>
        );
    }
}

export default AddChapterPage;
