import React, { Component } from "react";

import "./UiCardInfo.scss";

class UiCardInfo extends Component {
  constructor(props) {
    super(props);
  }

  state = {};

  render() {
    return (
      <div className="order-info">
        <p className="order-info-label">{this.props.label}</p>
        {this.props.link ?
          <p className="order-info-text"> <a href={this.props.link}>{this.props.text}</a></p>
          :
          <p className="order-info-text">{this.props.text}</p>
        }
      </div>
    );
  }
}
export default UiCardInfo;
