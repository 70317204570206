import React, { Component } from "react";

import Select from 'react-select';

import "./Catalog.scss";

import UiButtonOrange from "../../components/buttons/UiButtonOrange";
import UiFooter from "../../components/footer/UiFooter";
import UiHeader from "../../components/header/UiHeader";
import UiLoader from "../../components/modals/UiLoader";
import UiModalImageCrop from "../../components/modals/UiModalImageCrop";
import UiTextInput from "../../components/forms/UiTextInput";
import UiTextArea from "../../components/forms/UiTextArea";
import UiSelect from "../../components/forms/UiSelect";
import UiCheckItem from "../../components/forms/UiCheckItem";

import Env from "../../services/Env";
import { loadUser } from "../../services/Auth";
import { retrieveData } from "../../services/Storage";
import { edit } from "../../services/Categories";
 
class EditCategoriesPage extends Component {
  constructor(props) {
    super(props);
  }

  state = {
    data: null,
    
    title: "",
  
    /* modals */
    loader: false,
  };

  componentDidMount() {
    this._load();
  }



  _load() {
    let _user = loadUser();
    this.setState({
      user: _user,
    });

    let _data = retrieveData("categoryEdit");
    console.log("dara", _data);
    if (_data) {
      this.setState({ 
          data: _data 
        });
    }

 

    
  }

 

  _groupBy(array, key) {
    let finalArray = [];
    array.forEach(function (element) {
      var newArray = [];
      array.forEach(function (element1) {
        if (element[key] == element1[key]) {
          newArray.push(element1);
        }
      });
      if (!finalArray.some((arrVal) => newArray[0][key] == arrVal[0][key])) {
        finalArray.push(newArray);
      }
    });
    //console.log(array, finalArray)
    return finalArray;
  }

 

  _edit(id, val) {
    var _data = this.state.data;
    _data[id] = val;
    this.setState({ data: _data });
  }

 
 

  editCategories() {
    console.log(
      this.state.user.api_token,
      this.state.title,
    
    );
    if (
    
      this.state.data.title != "" 
    ) {
      edit(
        this.state.user.api_token,
        this.state.data.id,
        this.state.data.title,
      
      ).then((res) => {
        console.log(res);
        if (res.response) {
          let refer = "categories";
          if (retrieveData("referPage")) refer = retrieveData("referPage");
          this.props.history.push("/" + refer);
        }
      });
    } else {
    }
  }

  render() {
    if (this.state.data) {
      

      return (
        <div ref={this.divPage} id="page" className="edit-flower-page">
          <UiHeader page="categories" />
          <div className="content">
            <div className="section section-column">
              <h2>Редактировать страницу:</h2>
              <div className="users-table-wrap edit-flower-table-wrap">

                <div className="add-content">
                  <div className="modal-catalog-form">
                    <UiTextInput
                      placeholder="Название"
                      small
                      value={this.state.data.title}
                      onChange={(val) => this._edit("title", val)}
                    />
                 
                   

                    <UiButtonOrange
                      buttonText="Сохранить"
                      small
                      onClick={() => this.editCategories()}
                    />
                  </div>
                </div>

                
              </div>
            </div>
            <UiFooter />
          </div>

          {/* modals */}
          <UiLoader modalOpen={this.state.loader} />

          <UiModalImageCrop
            modalOpen={this.state.isDialogCrop}
            base64Image={this.state.base64Image}
            onSave={(val) => this._onSaveImage(val)}
            cancelClick={() => this.setState({ isDialogCrop: false })}
          />
        </div>
      );
    } else {
      return null;
    }
  }
}

export default EditCategoriesPage;
