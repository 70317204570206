export const getDateWithOffset = (_v) => {

    var today = new Date()
    var d = new Date(today)
    d.setDate(d.getDate() + _v);
    if (d.getDay() == 6 || d.getDay() == 0) {
        d.setDate(d.getDate() + 2);
    }


    var month = '' + (d.getMonth() + 1);
    var day = '' + d.getDate();
    var year = d.getFullYear();
    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [day, month, year].join('-');

}

export const getNonFormatedDateWithOffset = (_v) => {
    var today = new Date();
    var tomorrow = new Date();
    tomorrow.setDate(today.getDate() + _v);
    console.log(tomorrow);
    return tomorrow;
}

export const getDateWithOffsetAllowed = (_v) => {
    //1 4 6
    var value = null, arr = [];
    var today = new Date()
    var d = new Date(today)
    d.setDate(d.getDate() + _v);

    d.setDate(d.getDate() + (1 + 7 - d.getDay()) % 7);
    arr.push({
        value: d,
        text: formatDate(d)
    });

    today = new Date()
    d = new Date(today)
    d.setDate(d.getDate() + _v);

    d.setDate(d.getDate() + (3 + 7 - d.getDay()) % 7);
    arr.push({
        value: d,
        text: formatDate(d)
    });

    today = new Date()
    d = new Date(today)
    d.setDate(d.getDate() + _v);

    d.setDate(d.getDate() + (5 + 7 - d.getDay()) % 7);
    arr.push({
        value: d,
        text: formatDate(d)
    });

    arr.sort(function (a, b) {
        return new Date(a.value) - new Date(b.value);
    });

    return arr;
}

export const getDateWithOffsetAllowed2 = (_v, _sd) => {
    //1 4 6
    var value = null, arr = [];
    //console.log("ss", new Date(  (_sd)),    _sd)
    var today = new Date((_sd));
    var d = new Date(today);
    d.setDate(d.getDate() + _v);

    d.setDate(d.getDate() + (7 + 7 - d.getDay()) % 7);
    arr.push({
        value: d,
        text: convertStringToDateForSending(formatDate(d))
    });

    today = new Date((_sd))
    d = new Date(today)
    d.setDate(d.getDate() + _v);

    d.setDate(d.getDate() + (4 + 7 - d.getDay()) % 7);
    arr.push({
        value: d,
        text: convertStringToDateForSending(formatDate(d))
    });

    today = new Date((_sd))
    d = new Date(today)
    d.setDate(d.getDate() + _v);

    d.setDate(d.getDate() + (2 + 7 - d.getDay()) % 7);
    arr.push({
        value: d,
        text: convertStringToDateForSending(formatDate(d))
    });

    arr.sort(function (a, b) {
        return new Date(a.value) - new Date(b.value);
    });

    return arr;
}


export const convertStringToDateForSending = (_str) => {
    var s = _str.split("-");
    return [s[2], s[1], s[0]].join('-');
}

export const convertStringToDate = (_str) => {
    var s = _str.split("-");
    return [s[2], s[1], s[0]].join('-');
}


export const getFormatedCurrentDate = () => {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [year, month, day].join('-');

}

export const formatDate2Plus = (date, _v) => {
    var today = new Date(date);
    var d = new Date(today);
    d.setDate(d.getDate() + _v);

    var month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month].join('.');
}


export const formatDate2 = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month].join('.');
}

export const formatDate = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('-');
}



export const formatTime = (time) => {
    let l = time.split(":");
    return l[0] + ":" + l[1];
}
export const getTimeArrFromString = (time) => {
    let l = time.split(":");
    return l;
}

export const subtractTime1FromTime2 = (startTime, endTime) => {
    var s = startTime.split(':');
    var e = endTime.split(':');

    var end = new Date(0, 0, 0, parseInt(e[0], 10), parseInt(e[1], 10), 0);
    var start = new Date(0, 0, 0, parseInt(s[0], 10), parseInt(s[1], 10), 0);

    var elapsedMs = end - start;
    var elapsedMinutes = elapsedMs / 1000 / 60;

    return elapsedMinutes;
}

export const addTimeToCurrent = (time) => {
    let d = new Date();
    let date = new Date(d.getTime() + time * 60000);
    let hours = date.getHours();
    let minutes = date.getMinutes();

    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;
    return hours + ":" + minutes;
}

export const getTimeAgo = (endTime) => {
    const oneDay = 24 * 60 * 60 * 1000;
    const oneHour = 60 * 60 * 1000;
    const oneMinute = 60 * 1000;
    const firstDate = new Date();
    const secondDate = new Date(endTime);
    var diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
    var diffDaysH = Math.round(Math.abs((firstDate - secondDate) / oneHour));
    var diffDaysM = Math.round(Math.abs((firstDate - secondDate) / oneMinute));



    if (diffDays > 30) {
        diffDays = formatDateDots(endTime);
    } else {
        if (diffDays > 0) {
            diffDays = diffDays + global.locale.dates_day_ago;
        } else if (diffDays == 0) {
            diffDays = global.locale.dates_day_now;
        }
    }

    return diffDays;
}

export const getTimeRemaingMS = (startTime, endTime) => {
    let date1 = new Date(endTime);
    let date2 = new Date(startTime);
    let diffTime = Math.abs(date2 - date1);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
}

export const isInRange = (startTime, endTime, dayOffset) => {
    var s = startTime.split(':');
    var e = endTime.split(':');
    var current_time = new Date();
    var DD = 5 - 1;
    var offDay = 5 + parseInt(dayOffset) - 1;
    var offDay2 = 5 - parseInt(dayOffset) - 1;
    var MM = 0;
    var HH = parseInt(current_time.getHours());
    if (HH < 10) HH = "0" + HH;
    if (DD < 10) DD = "0" + DD;
    if (offDay < 10) offDay = "0" + offDay;
    if (offDay2 < 10) offDay2 = "0" + offDay2;
    if (parseInt(current_time.getMinutes()) < 10) MM = "0" + MM; else MM = parseInt(current_time.getMinutes());

    var open_time = Date.parse("2019-09-" + offDay2 + "T" + startTime);
    var close_time = Date.parse("2019-09-" + (offDay) + "T" + endTime);
    var check_val = Date.parse("2019-09-" + (DD) + "T" + HH + ":" + MM + ":00");

    // console.log( "2019-09-"+offDay2+"T"+startTime ,"2019-09-"+( offDay )+"T"+endTime , "2019-09-"+( DD )+"T"+HH+":"+MM+":00",  check_val > open_time && check_val < close_time )
    if (check_val > open_time && check_val < close_time) {
        return true;
    } else {
        return false;
    }
}

export const diffDateWithCurrent = (t) => {
    let current_time = new Date();
    let l = t.split(":");

    if (parseInt(l[0]) > current_time.getHours()) {
        return true;
    } else if (parseInt(l[0]) == current_time.getHours() && parseInt(l[1]) > current_time.getMinutes()) {
        return true;
    } else {
        return false;
    }
}

export const convertDayTitle = (dd) => {
    let arr = [
        { en: 'SUNDAY', ru: global.locale.dates_day_sun },
        { en: 'MONDAY', ru: global.locale.dates_day_mon },
        { en: 'TUESDAY', ru: global.locale.dates_day_tue },
        { en: 'WEDNESDAY', ru: global.locale.dates_day_wed },
        { en: 'THURSDAY', ru: global.locale.dates_day_thu },
        { en: 'FRIDAY', ru: global.locale.dates_day_fri },
        { en: 'SATURDAY', ru: global.locale.dates_day_sat },
    ];
    let val = "";
    arr.map((item) => {
        if (item.en == dd) val = item.ru;
    });
    return val;
}


export const getWeekArr = () => {
    return ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
}


export const getWeekTitleByNum = (num) => {
    let arr = [
        global.locale.dates_day_sun,
        global.locale.dates_day_mon,
        global.locale.dates_day_tue,
        global.locale.dates_day_wed,
        global.locale.dates_day_thu,
        global.locale.dates_day_fri,
        global.locale.dates_day_sat
    ];

    return arr[num];
}


export const getWeekByNum = (num) => {
    let arr = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    return arr[num];
}

export const getWeekNumber = (title) => {
    let fl = 0;
    let arr = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    arr.map((item, index) => { if (title.toUpperCase() == item) fl = index; })
    return fl;
}


export const formatDateDotsCurr = () => {
    var d = new Date(),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('.');
}

export const formatDateDots = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [day, month, year].join('.');
}

export const formatDateTimeDots = (date) => {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear(),
        hours = d.getHours(),
        minutes = d.getMinutes();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    if (hours < 10) hours = '0' + hours;
    if (minutes < 10) minutes = '0' + minutes;

    return [day, month, year].join('.') + ", " + [hours, minutes].join(':');;
}


export const getDayMonthName = (value) => {

    const monthNames = [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря",
    ];

    var d = new Date(value);
    return d.getDate() + '\u00A0' + monthNames[d.getMonth()];
};

export const getDayMonthYearName = (value) => {

    const monthNames = [
      "января",
      "февраля",
      "марта",
      "апреля",
      "мая",
      "июня",
      "июля",
      "августа",
      "сентября",
      "октября",
      "ноября",
      "декабря",
    ];
  
    var d = new Date(value);
    return d.getDate() + '\u00A0' + monthNames[d.getMonth()]  + '\u00A0' + d.getFullYear() ;
  };